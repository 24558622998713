import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import LeftSideMenu from "./LeftSideMenu";
import RightSideMenu from "./RightSideMenu";
import socket from "../../useSocket";
import { useSharedContext } from "../../Context";

const ChatPanel = () => {


    const { CurrentChatID, setCurrentChatID } = useSharedContext();

    // const [CurrentChatID, setCurrentChatID] = useState(null);
    const [employeeData, setEmployeeData] = useState([]);
    const [message, setMessage] = useState('');
    const [groupInfoPanelActive, setGroupInfoPanelActive] = useState(false);


    useEffect(() => {

        socket.emit('getEmployeeDetails');

        socket.on('EmployeeDataFetched', (data) => {
            setEmployeeData(data?.data);
        })

        return () => {
            socket.off('EmployeeDataFetched')
        }

    }, [])

    return (

        <Grid
            container
            sx={{
                width: '100%',
                height: '87vh',
                paddingTop: 1,
            }}>

            <Grid item xs={5} sx={{ bgcolor: 'none', height: '100%', width: '100%' }} >

                <LeftSideMenu
                    setCurrentChatID={setCurrentChatID}
                    CurrentChatID={CurrentChatID}
                    employeeData={employeeData}
                    message={message}
                    setMessage={setMessage}
                />

            </Grid>

            <Grid item xs={7} sx={{ bgcolor: 'none', height: '100%', width: '100%' }} >

                <RightSideMenu
                    CurrentChatID={CurrentChatID}
                    setCurrentChatID={setCurrentChatID}
                    employeeData={employeeData}
                    message={message}
                    setMessage={setMessage}
                    groupInfoPanelActive={groupInfoPanelActive}
                    setGroupInfoPanelActive={setGroupInfoPanelActive}
                />

            </Grid>

        </Grid>

    );
};

export default ChatPanel;

