import { configureStore } from "@reduxjs/toolkit";
import  CounterSlice  from "./features/counter/CounterSlice";


export default configureStore({
    reducer: {
        counter: CounterSlice
    }
})


