import { Avatar, Box, Button, Checkbox, Drawer, Grid, IconButton, Input, ListItemIcon, Menu, MenuItem, TextField, Tooltip, Typography } from '@mui/material'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import MoreVertIcon from '@mui/icons-material/MoreVert';
import SendIcon from '@mui/icons-material/Send';
import URL from "../Global/Utils/url_route";
import Loading from '../CustomComponents/loading';
import dayjs from 'dayjs';
import socket from '../../useSocket';
import ChatLanding from '../CustomComponents/chatLanding';
import NoChat from '../CustomComponents/noChat';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import ConfirmationModal from '../ModalComponents/confirmationModal';
import { useSharedContext } from '../../Context';
import Notification from '../ModalComponents/NotificationModal';
import GroupIcon from "@mui/icons-material/Group";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import GroupRemoveIcon from '@mui/icons-material/GroupRemove';
import logo1 from "../../images/logo_1.png";
import BorderColorIcon from '@mui/icons-material/BorderColor';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import debounce from 'lodash.debounce';
import DoneIcon from '@mui/icons-material/Done';
import NosearchFoundComponent from '../CustomComponents/nosearchFound';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';



const RightSideMenu = ({ CurrentChatID, setCurrentChatID, employeeData, message, setMessage, groupInfoPanelActive, setGroupInfoPanelActive }) => {

    const userinfo = JSON.parse(sessionStorage.getItem("user_info"));

    const { refreshLeftPanel, setRefreshLeftPanel, setNotificationContent, notificationPermissionGranted, deleteChatId, setDeleteChatId } = useSharedContext();

    const containerRef = useRef(null);
    const lastMessageRef = useRef(null);

    const groupActive = CurrentChatID && CurrentChatID.split('_').length > 2 ? true : false;

    const [isAtBottom, setIsAtBottom] = useState(true);
    const [isFocused, setIsFocused] = useState(false);
    const [chatData, setChatData] = useState([]);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(true);
    const [trigger, setTrigger] = useState(false);
    const [typing, setIsTyping] = useState(false);
    const [memberforAddGroupMemb, setMemberforAddGroupMemb] = useState([]);
    const [addMemGrpPanelView, setAddMemGrpPanelView] = useState(false);
    const [selectedIdsForNewGrp, setSelectedIdsForNewGrp] = React.useState([]);
    const [changeGroupName, setChangeGroupName] = useState(null);
    const [editGroupNameMode, setEditGroupNameMode] = useState(false);
    const [confirmationModal, setConfirmationModal] = useState(false);
    const [notificationObject, setNotificationObject] = useState({
        open: false,
        type: '',
        message: ''
    })
    const [searchDataForNewMember, setsearchDataForNewMember] = useState(null);
    const [debounceSearchDataForNewMember, debounceSetsearchDataForNewMember] = useState(searchDataForNewMember);

    const [editParticularChatMessage, setEditParticularChatMessage] = useState(null);

    const [anchorEl, setAnchorEl] = React.useState(null);

    const openOptions = Boolean(anchorEl);

    const handleClickOptions = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleCloseOptions = () => {
        setAnchorEl(null);
    };

    const typingTimeOut = useRef(null);

    const bottomRef = useRef(null);

    let url = `${URL}chat`

    const setDebouncedSearchNameValue = debounce((value) => {

        debounceSetsearchDataForNewMember(value);

    }, 500)

    const handleIntersection = useCallback((entries) => {
        const [entry] = entries;
        setIsAtBottom(entry.isIntersecting);
    }, [CurrentChatID, trigger]);

    useEffect(() => {
        const observer = new IntersectionObserver(handleIntersection, {
            root: containerRef.current,
            threshold: 1.0,
        });

        if (lastMessageRef.current) {
            observer.observe(lastMessageRef.current);
        }

        return () => {
            if (lastMessageRef.current) {
                observer.unobserve(lastMessageRef.current);
            }
        };
    }, [chatData, handleIntersection]);

    const scrollToBottom = () => {
        if (containerRef.current) {
            containerRef.current.scrollTo({
                top: containerRef.current.scrollHeight,
                behavior: 'smooth',
            });
        }
    };

    useEffect(() => {
        if (isAtBottom) {
            scrollToBottom();
        }
    }, [chatData, isAtBottom, CurrentChatID, trigger]);

    useEffect(() => {
        socket.on('refreshChatPage', () => {
            setTrigger(prevTrigger => !prevTrigger);
        })

        return () => {
            socket.off('refreshChatPage');
        }
    }, [])

    useEffect(() => {

        if (!groupActive) {
            setGroupInfoPanelActive(false);
        }

    }, [groupActive])

    useEffect(() => {

        function fetchData() {

            socket.emit('fetchMessage', { chat_id: CurrentChatID, myID: userinfo?.user_name, group: groupActive });

        }

        fetchData()

        socket.on('FetchedData', (data) => {
            setChatData(data.data);
            setLoading(false);
            setError(false);
        });

        socket.on('FetchChatError', (err) => {
            setError(true);
            setLoading(false);
            setNotificationObject({
                open: true,
                type: "error",
                message: err?.responseMsg ? err?.responseMsg : 'Something went wrong ! '
            })

        });

        socket.on('FetchEmployeeError', (err) => {
            setError(true);
            setLoading(false);
        });

        socket.on('deletedSuccess', () => {

            setRefreshLeftPanel(!refreshLeftPanel);
            setConfirmationModal(false);
            setCurrentChatID(null);
            setAnchorEl(null);
            setNotificationObject({
                open: true,
                type: "success",
                message: 'Successfully Chat Deleted ! '
            })
        })

        socket.on('modifiedGroupName', () => {

            setNotificationObject({
                open: true,
                type: "success",
                message: 'Group Name modified successfully...'
            })
            setEditGroupNameMode(false);
            setRefreshLeftPanel(!refreshLeftPanel);

        })

        socket.on('removedMemberFrmGrp', (data) => {

            setNotificationObject({
                open: true,
                type: "success",
                message: 'Employee removed from group successfully...'
            })
            setCurrentChatID(data?.changedId);
            setRefreshLeftPanel(!refreshLeftPanel);

        })

        socket.on('fetcheddMembersForNewGroupJoin', (data) => {
            setMemberforAddGroupMemb(data?.rows);
        })


        socket.on('AddedThisPersonToGroup', (data) => {

            setNotificationObject({
                open: true,
                type: "success",
                message: 'Employee Added . . . .'
            })

            setCurrentChatID(data?.chatId);
            setRefreshLeftPanel(!refreshLeftPanel);
            setAddMemGrpPanelView(false);
            setSelectedIdsForNewGrp([]);

        })


        socket.on('DeletedParticularChat', (data) => {

            setRefreshLeftPanel(!refreshLeftPanel);
            setDeleteChatId(null);

            setNotificationObject({
                open: true,
                type: "success",
                message: 'Chat Deleted Successfully . . . . '
            })

        })


        socket.on('ParticularChatHasUpdated', (data) => {

            setRefreshLeftPanel(!refreshLeftPanel);
            setDeleteChatId(null);

            setNotificationObject({
                open: true,
                type: "success",
                message: 'Chat Updated Successfully . . . . '
            })

        })


        return () => {

            socket.off('FetchedData');
            socket.off('FetchChatError');
            socket.off('FetchEmployeeError');
            socket.off('deletedSuccess');
            socket.off('newMessageNotification');
            socket.off('removedMemberFrmGrp');
            socket.off('modifiedGroupName');
            socket.off('fetcheddMembersForNewGroupJoin');
            socket.off('AddedThisPersonToGroup');
            socket.off('DeletedParticularChat');
            socket.off('ParticularChatHasUpdated')
        };

    }, [CurrentChatID, trigger, refreshLeftPanel]);

    useEffect(() => {
        handleAddNewMembers();
    }, [debounceSearchDataForNewMember])

    const handleSendMessage = () => {

        const messageData = {
            chat_id: CurrentChatID,
            message: message,
            emp_id: userinfo?.user_name,
            groupActive: groupActive,
        }

        socket.emit('newMessage', messageData);

        socket.on('sendMsgError', (data) => {
            console.error('Message not saved:', data);
        });

        socket.on('messageSaved', (data) => {
            setMessage('');
        });
    }

    const handleWritingNewMessage = (e) => {

        setMessage(e.target.value);

        if (!typing) {
            setIsTyping(true);
            socket.emit("typing", { chat_id: CurrentChatID, myID: userinfo?.user_name })
        }

        if (typingTimeOut.current) {
            clearTimeout(typingTimeOut.current);
        }

        typingTimeOut.current = setTimeout(() => {
            setIsTyping(false);
            socket.emit("typingStopped", { chat_id: CurrentChatID, myID: userinfo?.user_name })
        }, 3000);


    }

    const handleDeleteMessage = () => {
        socket.emit('DeleteParticularChat', { chat_id: CurrentChatID });
    }

    function gettingEmployeeName(emp_id) {
        const employeeName = Object.entries(employeeData).find(([key, value]) => value.emp_id == emp_id)?.[1];
        return employeeName ? employeeName?.f_name + " " + employeeName?.l_name : 'Unknown'
    }

    const handleAddNewMembers = () => {

        socket.emit('fetchMembersForNewGroupJoin', { chatId: CurrentChatID, searchData: debounceSearchDataForNewMember });

    }

    const handleCheckboxToggle = (id) => {
        setSelectedIdsForNewGrp((prev) =>
            prev.includes(id) ? prev.filter((itemId) => itemId !== id) : [...prev, id]
        );
    };


    const handleParticularChatEditMode = (id) => {

        setDeleteChatId(id)

        if (CurrentChatID.split('_').length > 2) {                                           //Group Messages Edit option , This One .... 

            const FindedChat = chatData?.rows.filter((item) => item?.id === id)[0]

            function GetTheText(item) {
                for (let key in item) {
                    if (key.endsWith('_text') && item[key] !== null) {
                        return key;
                    }
                }
            }

            const keyOfThePresentText = GetTheText(FindedChat);

            setEditParticularChatMessage(FindedChat[keyOfThePresentText])


        } else {                                                                            // One to One Chat Edit Message ....

            const { emp_1_text, emp_2_text } = chatData?.rows.filter((item) => item?.id === id)[0];

            setEditParticularChatMessage(emp_1_text ? emp_1_text : emp_2_text);

        }


    }

    const handleChangetheParticularChatMessage = () => {

        socket.emit('ChangetheParticularChat', { CurrentChatID: CurrentChatID, chatId: deleteChatId, message: editParticularChatMessage, myId: userinfo?.user_name })

    }


    return (

        <>
            {CurrentChatID ?

                <div style={{ height: '100%' }}>

                    {loading &&
                        <Grid style={{ display: 'flex', flexDirection: 'column', height: '100%', justifyContent: 'center', alignItems: 'center' }}>
                            <Loading />
                        </Grid>
                    }

                    {!loading && !error && (

                        <Grid style={{ display: 'flex', flexDirection: 'column', height: '100%', }}>

                            {/* Header Section */}
                            <Grid
                                style={{
                                    height: '8%',
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    padding: '0 16px',
                                    backgroundColor: '#142850',
                                    borderBottom: '1px solid',
                                }}
                            >
                                <Grid
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        gap: 14,
                                    }}
                                >

                                    {!groupActive ?

                                        <Avatar
                                            alt={!chatData?.profileUrl && chatData.emp_11_name === userinfo?.user_name
                                                ? `${chatData?.emp_22_name?.split(' ')[0]?.[0] || ''}${chatData?.emp_22_name?.split(' ').slice(-1)[0]?.[0] || ''}`
                                                : `${chatData?.emp_11_name?.split(' ')[0]?.[0] || ''}${chatData?.emp_11_name?.split(' ').slice(-1)[0]?.[0] || ''}`
                                            }
                                            src={chatData?.profileUrl || ''}
                                            sx={{
                                                width: 45,
                                                height: 45,
                                                backgroundColor: '#ccc',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                fontSize: '17px',
                                                color: '#fff',
                                            }}
                                        >
                                            {!chatData?.profileUrl && chatData.emp_11_name === userinfo?.user_name
                                                ? `${chatData?.emp_22_name?.split(' ')[0]?.[0] || ''}${chatData?.emp_22_name?.split(' ').slice(-1)[0]?.[0] || ''}`
                                                : `${chatData?.emp_11_name?.split(' ')[0]?.[0] || ''}${chatData?.emp_11_name?.split(' ').slice(-1)[0]?.[0] || ''}`
                                            }
                                        </Avatar>

                                        :

                                        <Avatar
                                            alt=" Group Chat Avatar"
                                            sx={{
                                                width: 45,
                                                height: 45,
                                                backgroundColor: '#ccc',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                fontSize: '17px',
                                                color: '#fff',
                                            }}
                                        >
                                            {<GroupIcon />}
                                        </Avatar>

                                    }

                                    {groupActive ?

                                        <div style={{ display: 'flex', flexDirection: 'column', gap: 0, }}>

                                            <Typography fontWeight={600} color='white'>
                                                {chatData?.groupName}
                                            </Typography>

                                            <Typography variant='body1' fontSize='12px' color='white'>

                                                {chatData?.groupDescription?.length > 50 ? `${chatData?.groupDescription.substring(0, 50)} ....` : chatData?.groupDescription}

                                            </Typography>

                                        </div>

                                        :

                                        <div style={{ display: 'flex', flexDirection: 'column', gap: 0, }}>

                                            <Typography fontWeight={600} color='white'>

                                                {chatData?.rows[0]?.emp_1_id == userinfo?.user_name ?
                                                    chatData?.emp_22_name : chatData?.emp_11_name
                                                }

                                            </Typography>
                                            <Typography variant='body1' fontSize='12px' color='white'>

                                                {chatData?.designation}

                                            </Typography>
                                        </div>

                                    }

                                </Grid>
                                <Grid
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        gap: 5,
                                    }}
                                >

                                    <Tooltip title="Menu">
                                        <IconButton
                                            onClick={handleClickOptions}
                                            size="small"
                                            aria-controls={openOptions ? 'account-menu' : undefined}
                                            aria-haspopup="true"
                                            aria-expanded={openOptions ? 'true' : undefined}
                                        >
                                            <MoreVertIcon sx={{
                                                color: 'white',
                                                cursor: 'pointer',
                                                '&:hover': {
                                                    transform: 'scale(1.2)',
                                                }
                                            }} />
                                        </IconButton>
                                    </Tooltip>

                                    <Tooltip title="Close Chat">

                                        <IconButton>
                                            <CloseRoundedIcon
                                                sx={{
                                                    color: 'white',
                                                    '&:hover': {
                                                        transform: 'scale(1.2)',
                                                    },
                                                }}
                                                onClick={() => {
                                                    setCurrentChatID(null)
                                                }}
                                            />
                                        </IconButton>

                                    </Tooltip>

                                    <Menu
                                        anchorEl={anchorEl}
                                        id="account-menu"
                                        open={openOptions}
                                        onClose={handleCloseOptions}
                                        slotProps={{
                                            paper: {
                                                elevation: 0,
                                                sx: {
                                                    overflow: 'visible',
                                                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                                    mt: 1.5,
                                                    '& .MuiAvatar-root': {
                                                        width: 32,
                                                        height: 32,
                                                        ml: -0.5,
                                                        mr: 1,
                                                    },
                                                    '&::before': {
                                                        content: '""',
                                                        display: 'block',
                                                        position: 'absolute',
                                                        top: 0,
                                                        right: 14,
                                                        width: 10,
                                                        height: 10,
                                                        bgcolor: 'background.paper',
                                                        transform: 'translateY(-50%) rotate(45deg)',
                                                        zIndex: 0,
                                                    },
                                                },
                                            },
                                        }}
                                        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                        anchorOrigin={{ horizontal: 'right', vertical: 'Bottom' }}
                                    >

                                        {groupActive &&

                                            <MenuItem
                                                onClick={() => {
                                                    setGroupInfoPanelActive(true);
                                                    setAnchorEl(null);
                                                }}
                                            >
                                                <ListItemIcon>
                                                    <InfoOutlinedIcon fontSize="small" />
                                                </ListItemIcon>
                                                Group Info
                                            </MenuItem>

                                        }

                                        <MenuItem
                                            onClick={() => {
                                                setConfirmationModal(true);
                                            }}
                                        >
                                            <ListItemIcon>
                                                <DeleteTwoToneIcon fontSize="small" />
                                            </ListItemIcon>
                                            Delete Chat
                                        </MenuItem>

                                    </Menu>

                                </Grid>
                            </Grid>


                            {/* Content Section */}
                            {!groupActive ?

                                // {/* One to One Chat Chat Section */}

                                (<Grid
                                    ref={containerRef}
                                    sx={{
                                        height: 'calc(100% - 8% - 16%)',
                                        overflowY: 'auto',
                                        flexGrow: 1,
                                        padding: '8px',
                                        '&::-webkit-scrollbar': {
                                            width: '8px',
                                        },
                                        '&::-webkit-scrollbar-track': {
                                            backgroundColor: '#f1f1f1',
                                        },
                                        '&::-webkit-scrollbar-thumb': {
                                            backgroundColor: '#097969',
                                            borderRadius: '4px',
                                        },
                                        '&::-webkit-scrollbar-thumb:hover': {
                                            backgroundColor: '#555',
                                        },
                                    }}
                                >

                                    {chatData?.rows?.length > 0 && (chatData?.rows[0]?.emp_1_text || chatData?.rows[0]?.emp_2_text) ? (

                                        chatData?.rows.map((item, index) => {

                                            const isLastMessage = index === chatData.length - 1;

                                            const loggedInUserId = parseInt(userinfo?.user_name);

                                            return (


                                                <Grid
                                                    key={item.id}
                                                    ref={isLastMessage ? lastMessageRef : null}
                                                    sx={{
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        marginBottom: '8px',
                                                    }}

                                                >

                                                    {item.emp_1_text && (

                                                        <Grid
                                                            sx={{
                                                                display: 'flex',
                                                                justifyContent: item.emp_1_id === loggedInUserId ? 'flex-end' : 'flex-start',
                                                                ':hover .Each_Chat_Options_2': {
                                                                    opacity: 1,
                                                                    visibility: 'true',
                                                                },
                                                            }}
                                                        >

                                                            {deleteChatId !== item?.id && (

                                                                item?.emp_1_id === loggedInUserId &&

                                                                <Grid
                                                                    display='flex'
                                                                    flexDirection='row'
                                                                    gap={1}
                                                                    pr={1}
                                                                >
                                                                    <EditIcon
                                                                        className='Each_Chat_Options_2'
                                                                        sx={{
                                                                            cursor: 'pointer',
                                                                            opacity: 0,
                                                                            visibility: 'false',
                                                                            color: '#A9A9A9',
                                                                            '&:hover': {
                                                                                transform: 'scale(1.2)',
                                                                                color: '#36454F',
                                                                            }
                                                                        }}

                                                                        onClick={() => handleParticularChatEditMode(item?.id)}
                                                                    />

                                                                    <DeleteIcon
                                                                        className="Each_Chat_Options_2"
                                                                        sx={{
                                                                            color: '#A9A9A9',
                                                                            fontSize: '25px',
                                                                            cursor: 'pointer',
                                                                            opacity: 0,
                                                                            transition: 'opacity 0.3s, transform 0.2s',
                                                                            '&:hover': {
                                                                                transform: 'scale(1.2)',
                                                                                color: '#36454F',
                                                                            },
                                                                            '&.visible': {
                                                                                opacity: 1,
                                                                            },
                                                                        }}
                                                                        onClick={() => {
                                                                            socket.emit('deleteParticularChat', { CurrentChatID: CurrentChatID, deleteId: item?.id });
                                                                        }}
                                                                    />

                                                                </Grid>
                                                            )

                                                            }


                                                            {deleteChatId === item?.id &&

                                                                <Grid
                                                                    sx={{
                                                                        width: '100%',
                                                                        backgroundColor: '#d1f4ff',
                                                                        borderRadius: 2,
                                                                        p: 1,
                                                                    }}
                                                                >

                                                                    <Grid width='100%' px={5}>
                                                                        <Input
                                                                            value={editParticularChatMessage}
                                                                            sx={{ width: '100%' }}
                                                                            placeholder="Edited Text to be Placed here okay . . . . "
                                                                            multiline
                                                                            minRows={1}
                                                                            maxRows={4}
                                                                            onChange={(e) => setEditParticularChatMessage(e.target.value)}
                                                                        />
                                                                    </Grid>

                                                                    <Grid textAlign='end' display='flex' justifyContent='flex-end' gap={2} pr={3} pt={2}>

                                                                        <Button
                                                                            variant="outlined"
                                                                            color='error'
                                                                            onClick={() => setDeleteChatId(null)}
                                                                        >
                                                                            Cancel
                                                                        </Button>


                                                                        <Button
                                                                            variant="outlined"
                                                                            color='success'
                                                                            onClick={() => handleChangetheParticularChatMessage()}
                                                                        >
                                                                            Update
                                                                        </Button>

                                                                        {/* <IconButton
                                                                            sx={{
                                                                                backgroundColor: '#d32f2f',
                                                                                color: 'white',
                                                                                '&:hover': {
                                                                                    backgroundColor: '#f44336'
                                                                                }
                                                                            }}
                                                                            onClick={() => setDeleteChatId(null)}
                                                                        >
                                                                            <CloseIcon />
                                                                        </IconButton>


                                                                        <IconButton
                                                                            sx={{
                                                                                backgroundColor: '#388e3c',
                                                                                color: 'white',
                                                                                '&:hover': {
                                                                                    backgroundColor: '#66bb6a'
                                                                                }
                                                                            }}
                                                                            onClick={() => handleChangetheParticularChatMessage()}

                                                                        >
                                                                            <DoneIcon />
                                                                        </IconButton> */}


                                                                    </Grid>

                                                                </Grid>
                                                            }

                                                            {deleteChatId !== item?.id &&

                                                                <Typography
                                                                    sx={{
                                                                        backgroundColor: item.emp_1_id === loggedInUserId ? '#d1f4ff' : '#d1f7c4',
                                                                        padding: '8px',
                                                                        borderRadius: '8px',
                                                                        maxWidth: '80%',
                                                                        wordWrap: 'break-word',
                                                                        textAlign: 'left',
                                                                        whiteSpace: 'pre-line'
                                                                    }}
                                                                >
                                                                    {item.emp_1_text}
                                                                    <Typography
                                                                        fontSize="12px"
                                                                        textAlign="right"
                                                                        sx={{ marginTop: '4px', color: '#888' }}
                                                                    >
                                                                        {dayjs(item.emp_1_time).format('HH:mm A')}
                                                                    </Typography>
                                                                </Typography>

                                                            }

                                                        </Grid>
                                                    )}

                                                    {item.emp_2_text && (

                                                        <Grid
                                                            sx={{
                                                                display: 'flex',
                                                                justifyContent: item.emp_2_id === loggedInUserId ? 'flex-end' : 'flex-start',
                                                                ':hover .Each_Chat_Options': {
                                                                    opacity: 1,
                                                                    visibility: 'true',
                                                                },
                                                            }}
                                                        >

                                                            {deleteChatId !== item?.id && (

                                                                item?.emp_2_id === loggedInUserId &&

                                                                <Grid
                                                                    display='flex'
                                                                    flexDirection='column'
                                                                    gap={1}
                                                                    pr={1}
                                                                >
                                                                    <EditIcon
                                                                        className='Each_Chat_Options'
                                                                        sx={{
                                                                            cursor: 'pointer',
                                                                            opacity: 0,
                                                                            visibility: 'false',
                                                                            color: '#A9A9A9',
                                                                            '&:hover': {
                                                                                transform: 'scale(1.2)',
                                                                                color: '#36454F'
                                                                            }
                                                                        }}

                                                                        onClick={() => handleParticularChatEditMode(item?.id)}
                                                                    />

                                                                    <DeleteIcon
                                                                        className="Each_Chat_Options"
                                                                        sx={{
                                                                            color: '#A9A9A9',
                                                                            fontSize: '25px',
                                                                            cursor: 'pointer',
                                                                            opacity: 0,
                                                                            transition: 'opacity 0.3s, transform 0.2s',
                                                                            '&:hover': {
                                                                                transform: 'scale(1.2)',
                                                                                color: '#36454F',
                                                                            },
                                                                            '&.visible': {
                                                                                opacity: 1,
                                                                            },
                                                                        }}
                                                                        onClick={() => {
                                                                            socket.emit('deleteParticularChat', { CurrentChatID: CurrentChatID, deleteId: item?.id });
                                                                        }}
                                                                    />

                                                                </Grid>

                                                            )}


                                                            {deleteChatId === item?.id &&

                                                                <Grid
                                                                    sx={{
                                                                        width: '100%',
                                                                        backgroundColor: '#d1f4ff',
                                                                        borderRadius: 2,
                                                                        p: 1,
                                                                    }}
                                                                >

                                                                    <Grid width='100%' px={5}>
                                                                        <Input
                                                                            value={editParticularChatMessage}
                                                                            sx={{ width: '100%' }}
                                                                            placeholder="Edited Text to be Placed here okay . . . . "
                                                                            multiline
                                                                            minRows={1}
                                                                            maxRows={4}
                                                                            onChange={(e) => setEditParticularChatMessage(e.target.value)}
                                                                        />
                                                                    </Grid>

                                                                    <Grid textAlign='end' display='flex' justifyContent='flex-end' gap={2} pr={3} pt={2}>

                                                                        {/* <IconButton
                                                                            sx={{
                                                                                backgroundColor: '#d32f2f',
                                                                                color: 'white',
                                                                                '&:hover': {
                                                                                    backgroundColor: '#f44336'
                                                                                }
                                                                            }}
                                                                            onClick={() => setDeleteChatId(null)}
                                                                        >
                                                                            <CloseIcon />
                                                                        </IconButton>


                                                                        <IconButton
                                                                            sx={{
                                                                                backgroundColor: '#388e3c',
                                                                                color: 'white',
                                                                                '&:hover': {
                                                                                    backgroundColor: '#66bb6a'
                                                                                }
                                                                            }}
                                                                            onClick={() => handleChangetheParticularChatMessage()}

                                                                        >
                                                                            <DoneIcon />
                                                                        </IconButton> */}

                                                                        <Button
                                                                            variant="outlined"
                                                                            color='error'
                                                                            onClick={() => setDeleteChatId(null)}
                                                                        >
                                                                            Cancel
                                                                        </Button>


                                                                        <Button
                                                                            variant="outlined"
                                                                            color='success'
                                                                            onClick={() => handleChangetheParticularChatMessage()}
                                                                        >
                                                                            Update
                                                                        </Button>

                                                                    </Grid>

                                                                </Grid>
                                                            }


                                                            {deleteChatId !== item?.id &&

                                                                <Typography
                                                                    className='Full_Text'
                                                                    sx={{
                                                                        backgroundColor: item.emp_1_id === loggedInUserId ? '#d1f7c4' : '#d1f4ff',
                                                                        padding: '8px',
                                                                        borderRadius: '8px',
                                                                        maxWidth: '80%',
                                                                        wordWrap: 'break-word',
                                                                        textAlign: 'left',
                                                                        whiteSpace: 'pre-line'
                                                                    }}
                                                                >
                                                                    {item.emp_2_text}
                                                                    <Typography
                                                                        fontSize="12px"
                                                                        textAlign="right"
                                                                        sx={{ marginTop: '4px', color: '#888' }}
                                                                    >
                                                                        {dayjs(item.emp_2_time).format('HH:mm A')}
                                                                    </Typography>

                                                                </Typography>

                                                            }


                                                        </Grid>
                                                    )}


                                                </Grid>


                                            );
                                        })

                                    ) : (

                                        <Typography
                                            variant='h5'
                                            textAlign='center'
                                            display='flex'
                                            justifyContent='center'
                                            alignItems='center'
                                            height='100%'
                                        >
                                            <NoChat />
                                        </Typography>


                                    )}

                                </Grid>)

                                :

                                // {/* Group Chat Section ... */}

                                (<Grid
                                    ref={containerRef}
                                    sx={{
                                        height: 'calc(100% - 8% - 16%)',
                                        overflowY: 'auto',
                                        flexGrow: 1,
                                        padding: '8px',
                                        '&::-webkit-scrollbar': {
                                            width: '8px',
                                        },
                                        '&::-webkit-scrollbar-track': {
                                            backgroundColor: '#f1f1f1',
                                        },
                                        '&::-webkit-scrollbar-thumb': {
                                            backgroundColor: '#097969',
                                            borderRadius: '4px',
                                        },
                                        '&::-webkit-scrollbar-thumb:hover': {
                                            backgroundColor: '#555',
                                        },
                                    }}

                                >

                                    {chatData?.rows?.length > 0 ? (

                                        chatData?.rows.map((item, index) => {

                                            const isLastMessage = index === chatData.length - 1;

                                            const loggedInUserId = parseInt(userinfo?.user_name);


                                            function findEmpIdWhichisTrrrrrue(item) {
                                                for (let key in item) {
                                                    if (key.endsWith('_text') && item[key] !== null) {
                                                        return key;
                                                    }
                                                }
                                            }


                                            const important = findEmpIdWhichisTrrrrrue(item);

                                            return (

                                                <Grid
                                                    key={item.id}
                                                    ref={isLastMessage ? lastMessageRef : null}
                                                    sx={{
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        marginBottom: '8px',
                                                    }}
                                                >

                                                    {item[`${important.split('_')[0]}_text`] !== null && (

                                                        <Grid
                                                            sx={{
                                                                display: 'flex',
                                                                justifyContent: parseInt(important.split('_')[0]) === loggedInUserId ? 'flex-end' : 'flex-start',
                                                                '&:hover .Each_Chat_Options_2': {
                                                                    opacity: 1,
                                                                }
                                                            }}
                                                        >
                                                            {/* Group Chat ---> Delete and Edit Icon For My Group Chat's  */}

                                                            {parseInt(important.split('_')[0]) === loggedInUserId && (

                                                                deleteChatId !== item?.id &&

                                                                <Grid
                                                                    display='flex'
                                                                    flexDirection='column'
                                                                    gap={1}
                                                                    pr={1}
                                                                >

                                                                    <EditIcon
                                                                        className='Each_Chat_Options_2'
                                                                        sx={{
                                                                            cursor: 'pointer',
                                                                            opacity: 0,
                                                                            visibility: 'false',
                                                                            color: '#A9A9A9',
                                                                            '&:hover': {
                                                                                transform: 'scale(1.2)',
                                                                                color: '#36454F',
                                                                            }
                                                                        }}
                                                                        onClick={() => handleParticularChatEditMode(item?.id)}
                                                                    />

                                                                    <DeleteIcon
                                                                        className="Each_Chat_Options_2"
                                                                        sx={{
                                                                            color: '#A9A9A9',
                                                                            fontSize: '25px',
                                                                            cursor: 'pointer',
                                                                            opacity: 0,
                                                                            transition: 'opacity 0.3s, transform 0.2s',
                                                                            '&:hover': {
                                                                                transform: 'scale(1.2)',
                                                                                color: '#36454F',
                                                                            },
                                                                            '&.visible': {
                                                                                opacity: 1,
                                                                            },
                                                                        }}
                                                                        onClick={() => {
                                                                            socket.emit('deleteParticularChat', { CurrentChatID: CurrentChatID, deleteId: item?.id });
                                                                        }}
                                                                    />

                                                                </Grid>

                                                            )}

                                                            {(deleteChatId !== item?.id && item[`${important.split('_')[0]}_text`]) &&

                                                                <Typography
                                                                    sx={{
                                                                        backgroundColor: parseInt(important.split('_')[0]) === loggedInUserId ? '#d1f7c4' : '#d1f4ff',
                                                                        padding: '8px',
                                                                        borderRadius: '8px',
                                                                        maxWidth: '80%',
                                                                        wordWrap: 'break-word',
                                                                        textAlign: 'left',
                                                                        whiteSpace: 'pre-line'
                                                                    }}
                                                                >
                                                                    {item[`${important.split('_')[0]}_text`]}

                                                                    <Grid sx={{ display: 'flex', gap: 2, justifyContent: 'space-between', alignItems: 'end' }}>
                                                                        <Typography
                                                                            fontSize="12px"
                                                                            sx={{ marginTop: '4px', color: '#888' }}
                                                                        >
                                                                            By : {item[`${important.split('_')[0]}_id`] == loggedInUserId ? 'You' : gettingEmployeeName(`${parseInt(important.split('_')[0])}`)}                                                                    </Typography>
                                                                        <Typography
                                                                            fontSize="12px"
                                                                            textAlign="right"
                                                                            sx={{ marginTop: '4px', color: '#888' }}
                                                                        >
                                                                            {dayjs(item[`${important.split('_')[0]}_time`]).format('HH:mm A')}
                                                                        </Typography>
                                                                    </Grid>

                                                                </Typography>

                                                            }


                                                            {(deleteChatId === item?.id && deleteChatId) &&

                                                                <Grid
                                                                    sx={{
                                                                        width: '100%',
                                                                        backgroundColor: '#d1f4ff',
                                                                        borderRadius: 2,
                                                                        p: 1,
                                                                    }}
                                                                >

                                                                    <Grid width='100%' px={5}>
                                                                        <Input
                                                                            value={editParticularChatMessage}
                                                                            sx={{ width: '100%' }}
                                                                            placeholder="Edited Text to be Placed here okay . . . . "
                                                                            multiline
                                                                            minRows={1}
                                                                            maxRows={4}
                                                                            onChange={(e) => setEditParticularChatMessage(e.target.value)}
                                                                        />
                                                                    </Grid>

                                                                    <Grid textAlign='end' display='flex' justifyContent='flex-end' gap={2} pr={3} pt={2}>


                                                                        {/* <IconButton
                                                                            sx={{
                                                                                backgroundColor: '#d32f2f',
                                                                                color: 'white',
                                                                                '&:hover': {
                                                                                    backgroundColor: '#f44336'
                                                                                }
                                                                            }}
                                                                            onClick={() => setDeleteChatId(null)}
                                                                        >
                                                                            <CloseIcon />
                                                                        </IconButton> */}

                                                                        <Button
                                                                            variant="outlined"
                                                                            color='error'
                                                                            onClick={() => setDeleteChatId(null)}
                                                                        >
                                                                            Cancel
                                                                        </Button>


                                                                        <Button
                                                                            variant="outlined"
                                                                            color='success'
                                                                            onClick={() => handleChangetheParticularChatMessage()}
                                                                        >
                                                                            Update
                                                                        </Button>

                                                                        {/* <IconButton
                                                                            sx={{
                                                                                backgroundColor: '#388e3c',
                                                                                color: 'white',
                                                                                '&:hover': {
                                                                                    backgroundColor: '#66bb6a'
                                                                                }
                                                                            }}
                                                                            onClick={() => handleChangetheParticularChatMessage()}

                                                                        >
                                                                            <DoneIcon />
                                                                        </IconButton> */}


                                                                    </Grid>

                                                                </Grid>
                                                            }


                                                        </Grid>
                                                    )}

                                                </Grid>

                                            );
                                        })

                                    ) : (

                                        <Typography
                                            variant='h5'
                                            textAlign='center'
                                            display='flex'
                                            justifyContent='center'
                                            alignItems='center'
                                            height='100%'
                                        >
                                            <NoChat />
                                        </Typography>

                                    )}

                                </Grid>)
                            }


                            {/* Footer Section */}
                            <Grid
                                style={{
                                    backgroundColor: '#C5C5C5',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    padding: '8px 16px',
                                    width: '100%',
                                    flexShrink: 0,
                                    gap: 20
                                }}
                            >
                                <Grid style={{ flexGrow: 1 }}>

                                    <TextField
                                        value={message}
                                        hiddenLabel
                                        placeholder="Type a message"
                                        id="filled-hidden-label-small"
                                        defaultValue=""
                                        variant="filled"
                                        size="small"
                                        multiline={true}
                                        maxRows={4}
                                        onFocus={() => setIsFocused(!isFocused)}
                                        onBlur={() => setIsFocused(!isFocused)}
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter' && message !== '' && !e.shiftKey) {
                                                handleSendMessage();
                                            }
                                        }}
                                        onChange={(e) => handleWritingNewMessage(e)}
                                        sx={{
                                            width: '100%',
                                            border: 'none',
                                            outline: 'none',
                                            boxSizing: 'border-box',
                                            transition: 'height 0.2s ease',
                                            borderRadius: '50%',
                                            '& .MuiFilledInput-root': {
                                                backgroundColor: 'white',
                                                borderBottom: 'none',
                                                '&:before': {
                                                    borderBottom: 'none',
                                                },
                                                '&:hover:not(.Mui-disabled):before': {
                                                    borderBottom: 'none',
                                                },
                                                '&.Mui-focused': {
                                                    borderBottom: 'none',
                                                    borderColor: 'none',
                                                    backgroundColor: 'white'
                                                },
                                                '&.hover': {
                                                    backgroundColor: 'white'
                                                }
                                            },
                                        }}
                                    />
                                </Grid>

                                <Grid
                                    sx={{
                                        display: 'flex',
                                        transition: 'transform 0.5s',
                                        transform: isFocused ? 'rotate(0deg)' : message ? '' : 'rotate(180deg)',
                                        cursor: 'pointer',
                                        pointerEvents: !message && 'none'

                                    }}
                                    onClick={handleSendMessage}
                                >
                                    <SendIcon color={message ? 'primary' : 'disabled'} />
                                </Grid>
                            </Grid>

                            {groupInfoPanelActive &&

                                <Drawer
                                    open={groupInfoPanelActive}
                                    sx={{
                                        width: '600px',
                                        flexShrink: 0,
                                        position: 'absolute',
                                        right: 0,
                                        top: 0,
                                        height: '100%',
                                        '& .MuiDrawer-paper': {
                                            width: '400px',
                                            height: '100%',
                                            backgroundColor: '#f0f0f0',
                                            paddingTop: 10,
                                            paddingBottom: 5,
                                        },
                                    }}
                                    anchor="right"
                                    variant="persistent"
                                >
                                    <Grid xs={12} sx={{ height: '100%' }}>

                                        {/* Header Section */}
                                        <Grid container xs={12} px={2} display='flex' justifyContent='center' alignItems='center'>
                                            <Grid xs={8} textAlign='left' >
                                                <Typography variant='h5' fontSize='18px'>Group Info </Typography>
                                            </Grid>
                                            <Grid xs={4} textAlign='right' >
                                                <IconButton
                                                    onClick={() => {
                                                        setGroupInfoPanelActive(false);
                                                    }}
                                                >
                                                    <CloseIcon sx={{ color: 'black' }} />
                                                </IconButton>

                                            </Grid>

                                        </Grid>

                                        {/* Group Name */}
                                        <Grid container xs={12} pt={1}>
                                            <Grid container xs={12} flexDirection='column' justifyContent="center" alignItems="center" gap={1}>
                                                <Grid
                                                    container
                                                    item
                                                    flexDirection="row"
                                                    justifyContent="center"
                                                    alignItems="center"
                                                >
                                                    <Grid item>
                                                        {editGroupNameMode ?
                                                            <Input
                                                                placeholder="Enter the Group Name"
                                                                value={changeGroupName}
                                                                onChange={(e) => setChangeGroupName(e.target.value)}

                                                            />
                                                            :

                                                            <Typography variant="h6" fontSize="22px">
                                                                {chatData?.groupName}
                                                            </Typography>
                                                        }

                                                    </Grid>
                                                    <Grid item>
                                                        {editGroupNameMode ?

                                                            (changeGroupName === chatData?.groupName || !changeGroupName) ?

                                                                <IconButton
                                                                    onClick={() => setEditGroupNameMode(false)}
                                                                >

                                                                    <CloseIcon
                                                                        color='error'
                                                                    />

                                                                </IconButton>

                                                                :

                                                                <IconButton
                                                                    disabled={!changeGroupName}
                                                                    onClick={() => {
                                                                        socket.emit('modifyGroupName', { chatId: chatData?.groupChatID, newGroupName: changeGroupName })
                                                                    }}
                                                                >
                                                                    <DoneAllIcon
                                                                        color={changeGroupName ? 'success' : 'disabled'}
                                                                    />
                                                                </IconButton>

                                                            :

                                                            <IconButton
                                                                onClick={() => {
                                                                    setEditGroupNameMode(true);
                                                                    setChangeGroupName(chatData?.groupName);
                                                                }}
                                                            >
                                                                <BorderColorIcon color='primary' />
                                                            </IconButton>}
                                                    </Grid>
                                                </Grid>

                                                <Grid
                                                    item
                                                    display='flex'
                                                    justifyContent="center"
                                                    alignItems="center"
                                                    px={5}
                                                    textAlign='justify'
                                                >
                                                    {chatData?.groupDescription}
                                                </Grid>
                                            </Grid>


                                        </Grid>

                                        {/* Group Members Section */}
                                        <Grid container xs={12} pt={1} px={2} pb={1} display='flex' justifyContent='center' alignItems='center'>

                                            <Grid xs={8}>
                                                <Typography>Members on Group ( {chatData?.groupChatID?.split('_').length} ) </Typography>
                                            </Grid>

                                            <Grid xs={4} textAlign='right'>
                                                <Tooltip
                                                    title={addMemGrpPanelView ? "Close" : "Add Member"}
                                                    placement="top"
                                                    arrow
                                                    componentsProps={{
                                                        tooltip: {
                                                            sx: {
                                                                fontSize: '0.9rem',
                                                                padding: '5px',
                                                                backgroundColor: '#333',
                                                                color: '#fff',
                                                                borderRadius: '8px',
                                                            },
                                                        },
                                                        arrow: {
                                                            sx: {
                                                                color: '#333',
                                                            },
                                                        },
                                                    }}
                                                >
                                                    {addMemGrpPanelView
                                                        ?

                                                        <IconButton
                                                            sx={{ color: 'red' }}
                                                            onClick={() => {
                                                                setAddMemGrpPanelView(false);
                                                                setSelectedIdsForNewGrp([]);
                                                            }}

                                                        >
                                                            <CloseIcon />
                                                        </IconButton>

                                                        :

                                                        <IconButton
                                                            sx={{ color: 'black' }}
                                                            onClick={() => {
                                                                handleAddNewMembers();
                                                                setAddMemGrpPanelView(true);
                                                            }}
                                                        >
                                                            <AddIcon />
                                                        </IconButton>

                                                    }

                                                </Tooltip>
                                            </Grid>
                                        </Grid>

                                        {/* All Members View Section */}

                                        {addMemGrpPanelView &&

                                            <Grid xs={12} px={2} pb={1}>
                                                <Input
                                                    placeholder="Search Employee ..."
                                                    sx={{
                                                        width: '100%'
                                                    }}
                                                    onChange={(e) => {
                                                        setDebouncedSearchNameValue(e.target.value);
                                                    }}
                                                />
                                            </Grid>
                                        }

                                        {selectedIdsForNewGrp.length > 0 && (
                                            <IconButton
                                                sx={{
                                                    position: 'absolute',
                                                    bottom: 45,
                                                    left: '45%',
                                                    p: 1.5,
                                                    backgroundColor: 'green',
                                                    borderBottom: '1px solid black',
                                                    zIndex: 99999,
                                                    color: 'white',
                                                    transition: 'background-color 0.3s ease',
                                                    '&:hover': {
                                                        backgroundColor: 'rgba(0, 150, 0, 0.9)',
                                                    },
                                                }}
                                                onClick={() => {
                                                    socket.emit('addThisPersonToGroup', { chatId: CurrentChatID, members: selectedIdsForNewGrp })
                                                }}
                                            >
                                                <DoneIcon />
                                            </IconButton>

                                        )}


                                        {!addMemGrpPanelView ?

                                            <Grid
                                                xs={12}
                                                pt={1}
                                                px={2}
                                                bgcolor=''
                                                height='80%'
                                                sx={{
                                                    overflowY: 'auto',
                                                    '&::-webkit-scrollbar': {
                                                        width: '8px',
                                                    },
                                                    '&::-webkit-scrollbar-track': {
                                                        backgroundColor: '#f1f1f1',
                                                    },
                                                    '&::-webkit-scrollbar-thumb': {
                                                        backgroundColor: '#004170',
                                                        borderRadius: '4px',
                                                    },
                                                    '&::-webkit-scrollbar-thumb:hover': {
                                                        backgroundColor: '#888',
                                                    },
                                                }}
                                            >

                                                {chatData?.groupChatID?.split('_').map((item, index) => (

                                                    <Grid container xs={12} px={1}
                                                        height={'12%'}
                                                        display='flex'
                                                        alignItems='center'
                                                        justifyContent='center'
                                                        sx={{
                                                            borderRadius: '2px',
                                                            ':hover .grp_member_opt_icon': {
                                                                opacity: 1,
                                                            }
                                                        }}
                                                    >

                                                        <Grid xs={2}>
                                                            <Avatar
                                                                alt=" Group Chat Avatar"
                                                                sx={{
                                                                    width: 50,
                                                                    height: 50,
                                                                    backgroundColor: '#ccc',
                                                                    display: 'flex',
                                                                    justifyContent: 'center',
                                                                    alignItems: 'center',
                                                                    fontSize: '17px',
                                                                    color: '#fff',
                                                                }}
                                                            >
                                                                {<GroupIcon />}
                                                            </Avatar>
                                                        </Grid>

                                                        <Grid xs={8} pl={1}>
                                                            <Typography fontSize={'17px'}>{gettingEmployeeName(item)}</Typography>
                                                        </Grid>

                                                        <Grid xs={2} textAlign='right'>
                                                            <Tooltip title={'Remove'}>

                                                                <IconButton
                                                                    className='grp_member_opt_icon'
                                                                    sx={{
                                                                        opacity: 0,
                                                                        transition: 'opacity 0.3 ease',
                                                                        color: 'red'
                                                                    }}
                                                                    onClick={(e) => {
                                                                        socket.emit('removeMemberFromGroup', { id: item, chatId: chatData?.groupChatID });
                                                                    }}
                                                                >
                                                                    <GroupRemoveIcon />
                                                                </IconButton>
                                                            </Tooltip>

                                                        </Grid>

                                                    </Grid>

                                                ))}

                                            </Grid>

                                            :

                                            <Grid xs={12} pt={1} px={2} bgcolor='' height='75%'
                                                sx={{
                                                    overflowY: 'auto',
                                                    '&::-webkit-scrollbar': {
                                                        width: '6px',
                                                    },
                                                    '&::-webkit-scrollbar-track': {
                                                        backgroundColor: '#f1f1f1',
                                                    },
                                                    '&::-webkit-scrollbar-thumb': {
                                                        backgroundColor: '#004170',
                                                        borderRadius: '4px',
                                                    },
                                                    '&::-webkit-scrollbar-thumb:hover': {
                                                        backgroundColor: '#888',
                                                    },
                                                }}
                                            >

                                                {memberforAddGroupMemb.length > 0 ?

                                                    memberforAddGroupMemb?.map((item, index) => (

                                                        <Grid
                                                            sx={{
                                                                display: 'flex',
                                                                flexDirection: 'row',
                                                                width: '100%',
                                                                justifyContent: 'center',
                                                                alignItems: 'center',
                                                                py: 1.3,
                                                                px: 1,
                                                                borderBottom: '1px solid #f0f0f0',
                                                                ":hover": {
                                                                    backgroundColor: '#f0f0f0',
                                                                    cursor: 'pointer'
                                                                }
                                                            }}
                                                            onClick={() => handleCheckboxToggle(item?.emp_id)}
                                                        >

                                                            <Box sx={{ display: 'flex', alignItems: 'center', mr: 2 }} >

                                                                <Checkbox
                                                                    color="success"
                                                                    checked={selectedIdsForNewGrp.includes(item?.emp_id)}
                                                                    onChange={() => handleCheckboxToggle(item?.emp_id)}
                                                                />

                                                                <Avatar
                                                                    alt={`${item?.f_name?.[0]}${item?.l_name?.[0]}`}
                                                                    src={item?.profileUrl || ''}
                                                                    sx={{
                                                                        width: 50,
                                                                        height: 50,
                                                                        backgroundColor: '#ccc',
                                                                        display: 'flex',
                                                                        justifyContent: 'center',
                                                                        alignItems: 'center',
                                                                        fontSize: '17px',
                                                                        color: '#fff',
                                                                    }}
                                                                >
                                                                    {!item?.profileUrl && `${item?.f_name?.[0]}${item?.l_name?.[0]}`}
                                                                </Avatar>
                                                            </Box>

                                                            <Box sx={{ flex: 1, }} >
                                                                <Typography fontSize={'17px'} >
                                                                    {`${item?.f_name} ${item?.l_name}`}
                                                                </Typography>
                                                            </Box>


                                                        </Grid>

                                                    ))
                                                    :

                                                    <Grid
                                                        sx={{
                                                            display: 'flex',
                                                            justifyContent: 'center',
                                                            alignItems: 'center',
                                                            height: '100%',
                                                        }}
                                                    >

                                                        <NosearchFoundComponent />

                                                    </Grid>


                                                }






                                            </Grid>

                                        }

                                    </Grid>
                                </Drawer>

                            }

                        </Grid >

                    )}


                    {error &&
                        <Grid style={{ display: 'flex', flexDirection: 'column', height: '100%', justifyContent: 'center', alignItems: 'center' }}>
                            <Typography variant='h5'>
                                Something Went Wrong ....
                            </Typography>
                        </Grid>
                    }

                </div>

                :

                <div style={{ height: '100%' }}>
                    <Grid style={{ display: 'flex', flexDirection: 'column', height: '100%', justifyContent: 'center', alignItems: 'center' }}>
                        <ChatLanding />
                    </Grid>
                </div>

            }

            <ConfirmationModal
                open={confirmationModal}
                onClose={() => {
                    setConfirmationModal(false);
                    setAnchorEl(null);
                }}
                onConfirm={handleDeleteMessage}
                title='Are you sure you want to delete this chat?'
                description='Once deleted, the chat and all its messages will be permanently removed !'
            />

            <Notification
                open={notificationObject.open}
                type={notificationObject.type}
                message={notificationObject.message}
                duration='2000'
                onClose={() => setNotificationObject({ open: 'false', type: '', message: '' })}
            />

        </>


    )
}

export default RightSideMenu