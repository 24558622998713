import React, { useState } from "react";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

const Notification = ({ open, type, message, duration, onClose }) => {
  return (
    <Snackbar
      open={open}
      autoHideDuration={duration}
      onClose={onClose}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      sx={{ paddingTop : 6 , zIndex : 99999 }}
    >
      <Alert 
        severity={type} 
        sx={{ width: "100%" , fontSize:'16px' , zIndex:99999 }}
        variant="filled"
    >
        {message}
      </Alert>
    </Snackbar>
  );
};

export default Notification;
