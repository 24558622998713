import { Avatar, Badge, Box, Button, Checkbox, Chip, Grid, IconButton, Input, InputBase, Paper, Slide, Tooltip, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import AddCommentIcon from '@mui/icons-material/AddComment';
import socket from '../../useSocket';
import { formatTimestampToTime } from '../Global/Utils/common_data';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useSharedContext } from '../../Context';
import Notification from '../ModalComponents/NotificationModal';
import GroupsIcon from '@mui/icons-material/Groups';
import GroupIcon from "@mui/icons-material/Group";
import debounce from 'lodash.debounce';
import NosearchFoundComponent from '../CustomComponents/nosearchFound';



const LeftSideMenu = ({ CurrentChatID, setCurrentChatID, setMessage }) => {

    const userinfo = JSON.parse(sessionStorage.getItem("user_info"));

    const { chatLists, setChatLists, refreshLeftPanel, setRefreshLeftPanel } = useSharedContext();
    const [openNewMsgModal, setOpenNewMsgModal] = useState(false);
    const [usedChats, setUsedChats] = useState([]);
    const [newChatsData, setNewChatsData] = useState([]);
    const [searchData, setSearchData] = useState(null);
    const [searchDataForAddNew, setSearchDataForAddNew] = useState(null);
    const [typingIds, setTypingIds] = useState([]);
    const [receiverIds, setReceiverIds] = useState([]);
    const [notificationObject, setNotificationObject] = useState({
        open: false,
        type: '',
        message: ''
    })
    const [createGroup, setCreateGroup] = useState(false);
    const [selectedEmployeeForGroup, setSelectedEmployeeForGroup] = useState([]);  //Example { name : 'Name' , Emp_id : '0000' }
    const [groupName, setGroupName] = useState(null);
    const [grpdesc, setGrpDesc] = useState(null);
    const [allNewChatData, setAllNewChatData] = useState([]);
    const [searchName, setSearchName] = useState(null);
    const [debounceSearchDataForNewMember, debounceSetsearchDataForNewMember] = useState(searchName);


    const ariaLabel = { 'aria-label': 'description' };

    useEffect(() => {

        socket.emit('fetchChats', { data: userinfo?.user_name, name: searchData })

        socket.on('fetchChatsError', (err) => {
            setNotificationObject({
                open: true,
                type: "error",
                message: err?.responseMsg ? err?.responseMsg : 'Something went wrong ! '
            })
        })

        socket.on('FetchEmployeeErrorFromChats', (data) => {
            console.error(data, 'from FetchEmployeeErrorFromChats')
        })

        socket.on('FetchedChatsData', (data) => {
            setChatLists(data?.data)
            getEmployeeID(data?.data)
        })

        socket.on('FetchedAllChatsForNewGroupCreation', (data) => {
            setAllNewChatData(data?.data)
        })

        socket.on('RefreshfetchChats', () => {
            setRefreshLeftPanel(!refreshLeftPanel);
        })

        socket.on('fetchedForCreateNewChat', (data) => {
            setNewChatsData(data?.data)
        })

        socket.on('closedNotification', () => {
            setRefreshLeftPanel(!refreshLeftPanel);
        })

        socket.on('typingNewMessage', (data) => {

            setTypingIds(prevData => {

                if (!prevData.includes(data?.data)) {
                    return [...prevData, data?.data];
                }
                return prevData;
            });

            setReceiverIds(prevData => {
                if (!prevData.includes(data?.receiver_id)) {
                    return [...prevData, data?.receiver_id];
                }
                return prevData;
            });
        });

        socket.on('GroupCreated', (data) => {

            setNotificationObject({
                open: true,
                type: "success",
                message: data?.responseMsg ? data?.responseMsg : 'Something went wrong ! '
            })

            setSelectedEmployeeForGroup([]);
            setGroupName(null);
            setGrpDesc(null);
            setCreateGroup(false);
            setRefreshLeftPanel(!refreshLeftPanel);

        })


        socket.on('stoppedTypingNewMessage', (data) => {

            setTypingIds((prevData) => prevData.filter(id => id !== data?.data));
            setReceiverIds((prevData) => prevData.filter(id => id !== data?.receiver_id));

        });




        return () => {
            // socket.off('fetchChatsError');
            // socket.off('FetchEmployeeErrorFromChats');
            // socket.off('FetchedChatsData');
            // socket.off('RefreshfetchChats');
            socket.off('closedNotification');
            socket.off('fetchedForCreateNewChat');
            socket.off('typing');
            socket.off('GroupCreated');
            socket.off('FetchedAllChatsForNewGroupCreation')
        }

    }, [socket, refreshLeftPanel, searchData])


    function getEmployeeID(data) {

        if (data) {

            let ids = [];

            data?.map((item, index) => {
                if (item?.chat_id?.split('_')[0] === userinfo?.user_name) {
                    ids.push(parseInt(item?.chat_id?.split('_')[1]))
                } else {
                    ids.push(parseInt(item?.chat_id?.split('_')[0]))
                }
            })

            ids.push(parseInt(userinfo?.user_name));

            setUsedChats(ids);

        } else {
            return;
        }
    }

    const handleAddNewChatPerson = () => {
        socket.emit(
            'fetchForCreateNewChat',
            { data: usedChats, name: searchDataForAddNew }
        );
    }

    useEffect(() => {
        handleAddNewChatPerson();
    }, [searchDataForAddNew]);


    useEffect(() => {

        handleFetchForCreateGroupWithMembers();

    }, [debounceSearchDataForNewMember])

    const handleCreateNewChat = (data) => {

        socket.emit(
            'createNewChat',
            { data: data, myId: userinfo?.user_name },
        );

        socket.on('NewChatSuccessCreated', (data) => {
            setOpenNewMsgModal(false);
            setCurrentChatID(data?.data);
            setRefreshLeftPanel(!refreshLeftPanel)
        })

    };

    const handleSelectEmployees = (emp_id, firstName, LastName) => {
        setSelectedEmployeeForGroup((prev) => {
            const isSelected = prev.some((item) => item.emp_id === emp_id);
            if (isSelected) {
                return prev.filter((item) => item.emp_id !== emp_id);
            } else {
                return [
                    ...prev,
                    {
                        emp_id: emp_id,
                        name: `${firstName} ${LastName}`,
                    },
                ];
            }
        });
    };


    const handleCreateNewGroup = () => {

        socket.emit('createNewGroup', { groupMembers: selectedEmployeeForGroup, groupName: groupName, myId: userinfo?.user_name, groupDescription: grpdesc });

    }


    const handleFetchForCreateGroupWithMembers = () => {
        socket.emit('FetchAllChatsForNewGroupCreation', { myId: userinfo?.user_name, name: debounceSearchDataForNewMember })
    }


    const setDebouncedSearchNameValue = debounce((value) => {

        debounceSetsearchDataForNewMember(value);

    }, 500)


    return (

        <Grid sx={{ height: '100%', p: 0, gap: 0, display: 'flex', flexDirection: 'row', borderRight: '0.5px solid gray' }}>

            {!openNewMsgModal && !createGroup &&  //This is panel is to show the chats and all Groups

                <Grid xs={12} sx={{ gap: 2, display: 'flex', flexDirection: 'column', px: 0, backgroundColor: 'white' }}>

                    {/* Search Bar for Searching Chats like ( One to One Chat and Group Chat ) */}
                    <Grid sx={{ px: 1, display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: 1 }} >
                        <Paper
                            variant='outlined'
                            sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: '100%', height: 37, outline: '1px solid' }}
                        >
                            <IconButton sx={{ p: '10px' }} aria-label="item">
                                <SearchIcon sx={{ color: 'black' }} />
                            </IconButton>
                            <InputBase
                                sx={{ ml: 1, flex: 1, color: 'black' }}
                                onChange={(e) => setSearchData(e.target.value)}
                                placeholder=" Search "
                                inputProps={{ 'aria-label': ' Search ' }}
                            />
                        </Paper>
                        <Tooltip title="Create New Chat">
                            <AddCommentIcon color='primary'
                                sx={{
                                    backgroundColor: '#f0f0f0',
                                    borderRadius: '50%',
                                    padding: '8px',
                                    fontSize: '40px',
                                    cursor: 'pointer',
                                }}
                                onClick={() => {
                                    handleAddNewChatPerson()
                                    setOpenNewMsgModal(true);
                                }}
                            />
                        </Tooltip>
                    </Grid>

                    {/* This is the Chats List */}
                    <Grid
                        sx={{
                            overflowY: 'auto',
                            '&::-webkit-scrollbar': {
                                width: '4px',
                            },
                            '&::-webkit-scrollbar-track': {
                                backgroundColor: '#f1f1f1',
                            },
                            '&::-webkit-scrollbar-thumb': {
                                backgroundColor: '#004170',
                                borderRadius: '4px',
                            },
                            '&::-webkit-scrollbar-thumb:hover': {
                                backgroundColor: '#888',
                            },
                        }}

                    >

                        {chatLists.length > 0 ?

                            (
                                chatLists.map((item, index) => (
                                    <Grid
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            width: '100%',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            py: 1.3,
                                            px: 1,
                                            pr: 2,
                                            borderBottom: '1px solid #f0f0f0',
                                            borderRadius: 1,
                                            backgroundColor: item?.chat_id === CurrentChatID ? '#f0f2f5' : 'transparent',
                                            ":hover": {
                                                backgroundColor: '#f0f0f0',
                                                cursor: 'pointer'
                                            },
                                            ':hover .department_name': {
                                                opacity: 1,
                                            }
                                        }}
                                        onClick={() => {
                                            setCurrentChatID(item?.chat_id);
                                            setMessage('')
                                            socket.emit('closeNotification', { chat_id: item?.chat_id })
                                        }}

                                    >

                                        <Box sx={{ display: 'flex', alignItems: 'center', mr: 2 }}> {/* Avatar Section */}

                                            {item?.groupChat === 1 ?
                                                <Avatar
                                                    alt=" Group Chat Avatar"
                                                    sx={{
                                                        width: 50,
                                                        height: 50,
                                                        backgroundColor: '#ccc',
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        fontSize: '17px',
                                                        color: '#fff',
                                                    }}
                                                >
                                                    {<GroupIcon />}
                                                </Avatar>
                                                :
                                                <Badge
                                                    overlap='circular'
                                                    variant={item?.activeStatus === 1 ? 'dot' : 'null'}
                                                    sx={{
                                                        "& .MuiBadge-dot": {
                                                            height: 12,
                                                            width: 12,
                                                            borderRadius: '50%',
                                                            backgroundColor: 'green',
                                                            border: '2px solid white'
                                                        }
                                                    }}
                                                >
                                                    <Avatar
                                                        alt={
                                                            item?.chat_id.split('_')[0] === userinfo?.user_name
                                                                ? `${item?.emp_2_name?.split(' ')[0]?.[0] || ''}${item?.emp_2_name?.split(' ').slice(-1)[0]?.[0] || ''}`
                                                                : `${item?.emp_1_name?.split(' ')[0]?.[0] || ''}${item?.emp_1_name?.split(' ').slice(-1)[0]?.[0] || ''}`
                                                        }
                                                        src={item?.profileUrl || ''}
                                                        sx={{
                                                            width: 50,
                                                            height: 50,
                                                            backgroundColor: '#ccc',
                                                            display: 'flex',
                                                            justifyContent: 'center',
                                                            alignItems: 'center',
                                                            fontSize: '17px',
                                                            color: '#fff',
                                                        }}
                                                    >
                                                        {
                                                            item?.chat_id.split('_')[0] === userinfo?.user_name
                                                                ? `${item?.emp_2_name?.split(' ')[0]?.[0] || ''}${item?.emp_2_name?.split(' ').slice(-1)[0]?.[0] || ''}`
                                                                : `${item?.emp_1_name?.split(' ')[0]?.[0] || ''}${item?.emp_1_name?.split(' ').slice(-1)[0]?.[0] || ''}`
                                                        }
                                                    </Avatar>

                                                </Badge>
                                            }
                                        </Box>

                                        {/* Text Section */}
                                        <Box sx={{ flex: 1, }} >

                                            {item?.groupChat === 1 ?
                                                <Typography variant="body1" sx={{ fontWeight: 600 }}>
                                                    {item?.groupName ? item?.groupName.length > 17 ? `${item?.groupName.slice(0, 17)}..` : item?.groupName : item?.groupName}
                                                </Typography>

                                                :
                                                <Grid display='flex' gap={1} >
                                                    <Tooltip
                                                        title={item?.departmentName ? `( ${item.departmentName} )` : ''}
                                                    >

                                                        <Typography variant="body1" sx={{ fontWeight: 600 }}>
                                                            {item?.chat_id.split('_')[0] === userinfo?.user_name ?
                                                                item?.emp_2_name.length > 16 ? `${item?.emp_2_name.slice(0, 16)}..` : item?.emp_2_name
                                                                : item?.emp_1_name.length > 16 ? `${item?.emp_1_name.slice(0, 16)}..` : item?.emp_1_name
                                                            }
                                                        </Typography>

                                                    </Tooltip>

                                                </Grid>
                                            }

                                            {
                                                typingIds?.includes(item?.chat_id) && receiverIds.includes(parseInt(userinfo?.user_name)) ? (
                                                    <Typography variant="body2" color="green">
                                                        Typing...
                                                    </Typography>
                                                ) : (

                                                    item?.groupChat === 1 ?
                                                        (
                                                            <Typography variant="body2" color="text.secondary">
                                                                {
                                                                    item?.lastMessage?.value
                                                                        ? item?.lastMessage?.value.length > 20
                                                                            ? item?.lastMessage?.value.slice(0, 20) + "..."
                                                                            : item?.lastMessage?.value
                                                                        : ''
                                                                }
                                                            </Typography>
                                                        ) : (

                                                            item?.lastMessage?.emp_1_text || item?.lastMessage?.emp_2_text ?
                                                                (
                                                                    <Typography variant="body2" color="text.secondary">
                                                                        {item?.lastMessage?.emp_1_text ?
                                                                            item?.lastMessage?.emp_1_text.slice(0, 20) + (item?.lastMessage?.emp_1_text?.length > 20 ? "..." : "")
                                                                            :
                                                                            item?.lastMessage?.emp_2_text.slice(0, 20) + (item?.lastMessage?.emp_2_text?.length > 20 ? "..." : "")}
                                                                    </Typography>

                                                                )
                                                                :
                                                                (
                                                                    ""
                                                                )
                                                        )

                                                )
                                            }


                                        </Box>

                                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', justifyContent: 'center', pb: 0, gap: 3 }}>{/* Time and Badge Section */}

                                            {item?.groupChat === 1 ?

                                                <Typography variant="caption" sx={{ mb: 0, mt: -2 }}>
                                                    {item?.lastMessage ? formatTimestampToTime(item?.lastMessage?.timeValue) : ''}
                                                </Typography>
                                                :

                                                <Typography variant="caption" sx={{ mb: 0, mt: -2 }}>
                                                    {item?.lastMessage?.emp_1_time ? formatTimestampToTime(item?.lastMessage?.emp_1_time) : ''}
                                                </Typography>
                                            }
                                            {item?.groupChat === 1 ?

                                                <Badge
                                                    sx={{ marginRight: 2, marginTop: -1 }}
                                                    badgeContent={userinfo?.user_name != item?.receiver_id ? item?.chat_read : null}
                                                    color="success"
                                                />

                                                :

                                                <Badge
                                                    sx={{ marginRight: 2, marginTop: -1 }}
                                                    badgeContent={userinfo?.user_name == item?.receiver_id ? item?.chat_read : null}
                                                    color="success"
                                                />
                                            }
                                        </Box>

                                    </Grid>
                                )))
                            :
                            (

                                <Grid
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        height: '70vh',
                                    }}
                                >

                                    <NosearchFoundComponent />

                                </Grid>

                            )

                        }

                    </Grid>

                </Grid>

            }



            {openNewMsgModal && !createGroup &&  // This Panel is to Create New Chat and Create New Group 

                <Grid xs={12} sx={{ gap: 2, display: 'flex', flexDirection: 'column', px: 0, backgroundColor: 'white', height: '100%' }}>

                    <Slide direction="right" in={openNewMsgModal} mountOnEnter unmountOnExit="right">

                        <Grid
                            sx={{
                                height: '100%',
                                boxShadow: '2px 0px 8px rgba(0, 0, 0, 0.1)',
                                zIndex: 2,
                            }}
                        >
                            <Grid sx={{ display: 'flex', gap: 3, py: 2, pl: 2 }}>
                                <ArrowBackIcon sx={{ cursor: 'pointer' }} onClick={() => setOpenNewMsgModal(false)} />
                                <Typography fontWeight={600}>New Chat</Typography>
                            </Grid>
                            <Grid sx={{ pt: 0, px: 2, pb: 1 }} >
                                <Paper
                                    variant='outlined'
                                    sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: '100%', height: 37, outline: '1px solid' }}
                                >
                                    <IconButton sx={{ p: '10px' }} aria-label="item">
                                        <SearchIcon sx={{ color: 'black' }} />
                                    </IconButton>
                                    <InputBase
                                        sx={{ ml: 1, flex: 1, color: 'black' }}
                                        onChange={(e) => setSearchDataForAddNew(e.target.value)}
                                        placeholder=" Search "
                                        inputProps={{ 'aria-label': ' Search ' }}
                                    />
                                </Paper>
                            </Grid>

                            <Grid
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    width: '100%',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    py: 1,
                                    px: 1,
                                    borderBottom: '1px solid #AEAEAE',
                                    ":hover": {
                                        backgroundColor: '#f0f0f0',
                                        cursor: 'pointer'
                                    }
                                }}

                                onClick={() => {
                                    handleFetchForCreateGroupWithMembers();
                                    setCreateGroup(true);
                                    setOpenNewMsgModal(false);
                                }}

                            >

                                <Box sx={{ display: 'flex', alignItems: 'center', mr: 2 }}>

                                    <Box
                                        sx={{
                                            backgroundColor: '#ccc',
                                            p: 1,
                                            borderRadius: '50%',
                                            width: 50,
                                            height: 50,
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <GroupsIcon
                                            sx={{
                                                fontSize: '30px',
                                                color: 'green'
                                            }}
                                        />
                                    </Box>
                                </Box>

                                <Box sx={{ flex: 1, height: '100%', }} >
                                    <Typography variant="body1" sx={{ fontWeight: 600 }}>
                                        Create New Group
                                    </Typography>
                                </Box>

                            </Grid>


                            <Grid
                                sx={{
                                    overflowY: 'auto',
                                    '&::-webkit-scrollbar': {
                                        width: '4px',
                                    },
                                    '&::-webkit-scrollbar-track': {
                                        backgroundColor: '#f1f1f1',
                                    },
                                    '&::-webkit-scrollbar-thumb': {
                                        backgroundColor: '#004170',
                                        borderRadius: '4px',
                                    },
                                    '&::-webkit-scrollbar-thumb:hover': {
                                        backgroundColor: '#888',
                                    },
                                    height: '77%',
                                    pt: 1,
                                }}

                            >

                                {newChatsData.map((item, index) => (
                                    <Grid
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            width: '100%',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            py: 1.3,
                                            px: 1,
                                            borderBottom: '1px solid #f0f0f0',
                                            ":hover": {
                                                backgroundColor: '#f0f0f0',
                                                cursor: 'pointer'
                                            }
                                        }}
                                        onClick={() => handleCreateNewChat(item?.emp_id)}

                                    >

                                        <Box sx={{ display: 'flex', alignItems: 'center', mr: 2 }}>
                                            <Badge
                                                overlap='circular'
                                                variant={item?.row_exists === 1 ? 'dot' : 'null'}
                                                sx={{
                                                    "& .MuiBadge-dot": {
                                                        height: 12,
                                                        width: 12,
                                                        borderRadius: '50%',
                                                        backgroundColor: 'green',
                                                        border: '2px solid white'
                                                    }
                                                }}
                                            >
                                                <Avatar
                                                    alt={`${item?.f_name?.[0]}${item?.l_name?.[0]}`}
                                                    src={item?.profileUrl || ''}
                                                    sx={{
                                                        width: 50,
                                                        height: 50,
                                                        backgroundColor: '#ccc',
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        fontSize: '17px',
                                                        color: '#fff',
                                                    }}
                                                >
                                                    {!item?.profileUrl && `${item?.f_name?.[0]}${item?.l_name?.[0]}`}
                                                </Avatar>


                                            </Badge>
                                        </Box>

                                        <Box sx={{ flex: 1, }} >
                                            <Typography variant="body1" sx={{ fontWeight: 600 }}>
                                                {`${item?.f_name} ${item?.l_name}`}
                                            </Typography>
                                        </Box>

                                    </Grid>

                                ))}

                            </Grid>

                        </Grid>
                    </Slide>
                </Grid>

            }



            {!openNewMsgModal && createGroup &&  // Create new Group Panel

                <Grid xs={12} sx={{ gap: 2, display: 'flex', flexDirection: 'column', px: 0, backgroundColor: 'white', height: '100%' }}>

                    <Grid
                        sx={{
                            height: '100%',
                            boxShadow: '2px 0px 8px rgba(0, 0, 0, 0.1)',
                            zIndex: 2,
                        }}
                    >

                        <Grid sx={{ display: 'flex', gap: 3, py: 2, pl: 2, height: '7%' }}>

                            <ArrowBackIcon sx={{ cursor: 'pointer' }}
                                onClick={() => {
                                    setOpenNewMsgModal(true);
                                    setCreateGroup(false);
                                    setSelectedEmployeeForGroup([]);

                                }} />
                            <Typography fontWeight={600}>New Group</Typography>

                        </Grid>

                        <Grid xs={12} display='flex' gap={3} sx={{ pt: 0, px: 2, pb: 1, height: '5%', }} >
                            <Grid xs={6}>

                                <Input
                                    placeholder="Group Name"
                                    inputProps={ariaLabel}
                                    sx={{ width: '100%' }}
                                    onChange={(e) => setGroupName(e.target.value)}
                                />

                            </Grid>
                            <Grid xs={6}>

                                <Input
                                    placeholder="Group Description"
                                    inputProps={ariaLabel}
                                    sx={{ width: '100%' }}
                                    onChange={(e) => setGrpDesc(e.target.value)}
                                />

                            </Grid>


                        </Grid>

                        <Grid
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                width: '100%',
                                py: 0.8,
                                px: 1,
                                ":hover": {
                                    backgroundColor: '#f0f0f0',
                                    cursor: 'pointer'
                                },
                                height: '10%',
                            }}

                        >
                            {
                                selectedEmployeeForGroup.length > 0 ? (
                                    <Box sx={{ flex: 1, maxHeight: '90px', overflowY: 'auto', }}>
                                        {selectedEmployeeForGroup.map((item, index) => (
                                            <Chip
                                                key={index}
                                                label={item?.name}
                                            />
                                        ))}
                                    </Box>
                                ) : (
                                    <Box sx={{ flex: 1, height: '100%', display: 'flex', alignItems: 'center', pl: 1 }}>
                                        <Typography variant="body1" fontSize="18px" fontWeight={600} >
                                            Employees on BRHMA
                                        </Typography>
                                    </Box>
                                )
                            }

                        </Grid>


                        <Grid sx={{ pt: 0, px: 2, pb: 0, height: '5%', }} >

                            <Input
                                placeholder="Search Employee ..."
                                inputProps={ariaLabel}
                                sx={{ width: '100%' }}
                                onChange={(e) => setDebouncedSearchNameValue(e.target.value)}
                            />

                        </Grid>


                        <Grid
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                flex: 1,
                                overflowY: 'auto',
                                '&::-webkit-scrollbar': {
                                    width: '4px',
                                },
                                '&::-webkit-scrollbar-track': {
                                    backgroundColor: '#f1f1f1',
                                },
                                '&::-webkit-scrollbar-thumb': {
                                    backgroundColor: '#004170',
                                    borderRadius: '4px',
                                },
                                '&::-webkit-scrollbar-thumb:hover': {
                                    backgroundColor: '#888',
                                },
                                height: '73%',
                            }}

                        >
                            {allNewChatData?.length > 0
                                ?
                                allNewChatData.map((item, index) => (

                                    <Grid
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            width: '100%',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            py: 1.3,
                                            px: 1,
                                            borderBottom: '1px solid #f0f0f0',
                                            ":hover": {
                                                backgroundColor: '#f0f0f0',
                                                cursor: 'pointer'
                                            }
                                        }}
                                        onClick={(e) => handleSelectEmployees(item?.emp_id, item?.f_name, item?.l_name)}

                                    >

                                        <Box sx={{ display: 'flex', alignItems: 'center', mr: 2 }}>

                                            <Checkbox
                                                color="success"
                                                checked={selectedEmployeeForGroup.some((itm) => itm?.emp_id === item?.emp_id)}
                                                onChange={() => handleSelectEmployees(item?.emp_id, item?.f_name, item?.l_name)}
                                            />

                                            <Badge
                                                overlap='circular'
                                                variant={item?.row_exists === 1 ? 'dot' : 'null'}
                                                sx={{
                                                    "& .MuiBadge-dot": {
                                                        height: 12,
                                                        width: 12,
                                                        borderRadius: '50%',
                                                        backgroundColor: 'green',
                                                        border: '2px solid white'
                                                    }
                                                }}
                                            >
                                                <Avatar
                                                    alt={`${item?.f_name?.[0]}${item?.l_name?.[0]}`}
                                                    src={item?.profileUrl || ''}
                                                    sx={{
                                                        width: 50,
                                                        height: 50,
                                                        backgroundColor: '#ccc',
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        fontSize: '17px',
                                                        color: '#fff',
                                                    }}
                                                >
                                                    {!item?.profileUrl && `${item?.f_name?.[0]}${item?.l_name?.[0]}`}
                                                </Avatar>


                                            </Badge>
                                        </Box>

                                        <Box sx={{ flex: 1, }} >
                                            <Typography variant="body1" sx={{ fontWeight: 600 }}>
                                                {`${item?.f_name} ${item?.l_name}`}
                                            </Typography>
                                        </Box>

                                    </Grid>

                                ))

                                :
                                <Grid
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        height: '100%',
                                    }}
                                >

                                    <NosearchFoundComponent />

                                </Grid>
                            }

                        </Grid>

                    </Grid>

                </Grid>

            }



            {/* Create Group Button , PopUp Button..... */}
            {
                selectedEmployeeForGroup.length > 1 && groupName && (

                    <Button
                        sx={{
                            position: 'absolute',
                            bottom: 45,
                            left: '15%',
                            borderBottom: '1px solid yellow',
                            borderRadius: '8px',
                            padding: '8px 20px',
                            zIndex: 99999,
                        }}
                        color="success"
                        variant="contained"
                        onClick={handleCreateNewGroup}
                    >
                        Create Group
                    </Button>

                )
            }



            <Notification
                open={notificationObject.open}
                type={notificationObject.type}
                message={notificationObject.message}
                duration='2000'
                onClose={() => setNotificationObject({ open: 'false', type: '', message: '' })}
            />


        </Grid >
    )
}


export default LeftSideMenu