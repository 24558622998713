import { Box, Grid, IconButton } from '@mui/material'
import React from 'react'
import logo from "../../../images/logo_1.png"
import mosLogo from "../../../images/moslogo.png"

const Header = () => {
    return (

        <Grid
            container
            bgcolor="#142850"
            height="7vh"
            position="fixed"
            top={0}
            left={0}
            width="100%"
            justifyContent="space-between"
            alignItems="center"
            sx={{
                px: 2,
            }}
        >
            <Grid
                item
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    height: '100%',
                }}
            >
                <img
                    src={logo}
                    alt="Logo"
                    style={{
                        maxWidth: 'auto',
                        maxHeight: '15vh',
                        objectFit: 'contain',
                    }}
                />
            </Grid>

            <Grid
                item
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    height: '100%',
                }}
            >
                <img
                    src={mosLogo}
                    alt="MOS Logo"
                    style={{
                        maxWidth: 'auto',
                        maxHeight: '14vh',
                        objectFit: 'contain',
                        marginTop: 12,
                    }}
                />
            </Grid>
        </Grid>
    )
}

export default Header
