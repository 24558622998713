import React, { useEffect } from 'react'
import { Badge, Box, Card, Fab, Typography } from '@mui/material';
import ChatIcon from '@mui/icons-material/Chat';
import { useSharedContext } from '../../Context';
import { useState } from 'react';
import socket from '../../useSocket';
import logo1 from "../../images/logo_1.png";


const FloatButton = () => {

    const { chatLists, openMessagPanel, setOpenMessagePanel, setChatLists, setRefreshLeftPanel,
        userinfo, refreshLeftPanel, CurrentChatID, setCurrentChatID, notificationPermissionGranted, setNotificationContent } = useSharedContext();


    const [isHovered, setIsHovered] = useState(false);

    const chatsFiltered = chatLists.filter((item) => item.chat_read > 0);

    const totalChats = chatsFiltered?.filter(item => (item?.groupChat === 0 && item?.receiver_id === parseInt(userinfo?.user_name)) || (item?.groupChat === 1 && item?.receiver_id !== parseInt(userinfo?.user_name)))
        .map(item => item?.chat_read || 0)
        .reduce((total, chatRead) => total + chatRead, 0);


    useEffect(() => {

        function demo() {

            socket.emit('fetchChats', { data: userinfo?.user_name, name: '' });

            socket.on('FetchedChatsData', (data) => {
                setChatLists(data?.data)

            })

        }

        demo();

        socket.on('refreshChatPage', () => {

            demo();

        })

    }, [socket, refreshLeftPanel])



    useEffect(() => {
        socket.on('newMessageNotification', (data) => {

            if (notificationPermissionGranted) {
                if (data?.ReceiverID === userinfo?.user_name) {

                    setNotificationContent({
                        title: `Received message from ${data?.fromID}`,
                        options: {
                            body: `${data?.message.slice(0, 45)}...`.replace('http://localhost:3000', ''),
                            icon: logo1,
                            tag: `message-notification-${Date.now()}`,
                            lang: "en",
                            dir: "ltr",
                            silent: false,
                            renotify: true,
                            vibrate: [200, 100, 200],
                        },
                    });
                }
            } else {

                const toast = document.createElement("div");
                toast.textContent = "Notifications are blocked. Please enable them in your browser settings.";
                toast.style.position = "fixed";
                toast.style.bottom = "20px";
                toast.style.right = "20px";
                toast.style.backgroundColor = "#333";
                toast.style.color = "#fff";
                toast.style.padding = "10px 20px";
                toast.style.borderRadius = "5px";
                toast.style.boxShadow = "0px 4px 6px rgba(0, 0, 0, 0.2)";
                toast.style.zIndex = "1000";
                toast.style.fontSize = "14px";
                toast.style.opacity = "0";
                toast.style.transition = "opacity 0.5s ease";

                document.body.appendChild(toast);

                setTimeout(() => {
                    toast.style.opacity = "1";
                }, 100);

                setTimeout(() => {
                    toast.style.opacity = "0";
                    setTimeout(() => {
                        document.body.removeChild(toast);
                    }, 500);
                }, 3000);

            }

        })

    }, [socket])


    return (
        <>
            <Fab
                color="primary"
                aria-label="add"
                size="medium"
                sx={{
                    position: 'fixed',
                    bottom: 20,
                    right: 20,
                }}
                onClick={() => setOpenMessagePanel(true)}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={(e) => {
                    const relatedTarget = e.relatedTarget;
                    if (relatedTarget?.className?.includes("Notification_hover_view_panel")) {
                        return;
                    }
                    setIsHovered(false);
                }}
            >
                <Badge
                    badgeContent={totalChats}
                    color="success"
                    sx={{
                        '& .MuiBadge-badge': {
                            left: 13,
                            top: -9,
                        }
                    }}
                >
                    <ChatIcon />
                </Badge>

            </Fab>


            {(chatsFiltered.length > 0 && !openMessagPanel && totalChats > 0) &&

                <Card
                    className="Notification_hover_view_panel"
                    sx={{
                        opacity: isHovered ? 1 : 0,
                        position: 'fixed',
                        bottom: 70,
                        right: 20,
                        width: '350px',
                        maxHeight: '300px',
                        backgroundColor: 'rgba(255, 255, 255, 0.5)',
                        color: 'white',
                        display: isHovered ? 'flex' : 'none',
                        flexDirection: 'column',
                        borderRadius: 2,
                        boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
                        zIndex: isHovered ? 999 : 2,
                        transition: 'opacity 0.3s ease',
                        overflowY: 'auto',
                        '&::-webkit-scrollbar': {
                            width: '8px',
                        },
                        '&::-webkit-scrollbar-track': {
                            backgroundColor: '#f1f1f1',
                        },
                        '&::-webkit-scrollbar-thumb': {
                            backgroundColor: '#004170',
                            borderRadius: '4px',
                        },
                        '&::-webkit-scrollbar-thumb:hover': {
                            backgroundColor: '#888',
                        },
                    }}
                    onMouseEnter={() => setIsHovered(true)}
                    onMouseLeave={() => setIsHovered(false)}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 1,
                            padding: 1,
                            borderRadius: 1
                        }}
                    >
                        {/* Notification Name for the One to One Chat . . . . */}

                        {chatsFiltered.map((item) => (
                            item?.groupChat === 0 ? (
                                item?.receiver_id === parseInt(userinfo?.user_name) && (
                                    <Card
                                        variant='outlined'
                                        sx={{
                                            maxWidth: '100%',
                                            p: 1,
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            px: 2,
                                            gap: 2,
                                            '&:hover': {
                                                backgroundColor: '#E5E4E2',
                                                cursor: 'pointer'
                                            },
                                            // zIndex: 2
                                        }}
                                        onClick={() => {
                                            setOpenMessagePanel(true);
                                            setCurrentChatID(item?.chat_id);
                                            socket.emit('closeNotification', { chat_id: item?.chat_id })
                                        }}
                                    >
                                        <Typography sx={{ width: '100%', wordWrap: 'break-word' }}>
                                            {`New Message From `}
                                            <span style={{ fontWeight: 'bold' }}>
                                                {item?.chat_id.split('_')[0] === userinfo?.user_name
                                                    ? item?.emp_2_name
                                                    : item?.emp_1_name}
                                            </span>
                                        </Typography>
                                        <Badge badgeContent={item?.chat_read} color='success' />
                                    </Card>
                                )

                            )
                                :

                                (
                                    item?.receiver_id !== parseInt(userinfo?.user_name) && ( //Notification for Group Chat okay . . . .
                                        <Card
                                            variant='outlined'
                                            sx={{
                                                maxWidth: '100%',
                                                p: 1,
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                px: 2,
                                                gap: 2,
                                                '&:hover': {
                                                    backgroundColor: '#E5E4E2',
                                                    cursor: 'pointer',
                                                },
                                                // zIndex: 2
                                            }}
                                            onClick={() => {
                                                setCurrentChatID(item?.chat_id);
                                                setOpenMessagePanel(true);
                                                socket.emit('closeNotification', { chat_id: item?.chat_id })
                                            }}
                                        >
                                            <Typography sx={{ width: '100%', wordWrap: 'break-word' }}>
                                                {`New Message From `}
                                                <span style={{ fontWeight: 'bold' }}>
                                                    {item?.groupName}
                                                </span>
                                            </Typography>
                                            <Badge badgeContent={item?.chat_read} color='success' />
                                        </Card>
                                    )
                                )
                        ))}

                    </Box>

                </Card>

            }
        </>



    )
}

export default FloatButton