import {
    Alert, Box, Button, Checkbox, Grid, IconButton, InputBase,
    Modal, Paper, Snackbar, Table, TableBody, TableCell,
    TableContainer, TableHead, TableRow, TextField, Typography
} from '@mui/material'
import React, { useState } from 'react'
import { useFetchData } from '../EmployeeTable/customHook';
import URL from '../Global/Utils/url_route';
import axios from 'axios';
import CircularProgress from '@mui/material/CircularProgress';
import EditIcon from '@mui/icons-material/Edit';
import SearchIcon from '@mui/icons-material/Search';
import debounce from 'lodash.debounce';
import DatanotFound from '../CustomComponents/datanotfound';



const UserRoleManagement = () => {

    const userinfo = JSON.parse(sessionStorage.getItem("user_info"));

    const employee_id = userinfo?.user_details?.emp_id;

    const defaultData = [
        {
            "id": 1,
            "menuName": "Dashboard",
            "list": true,
            "add": true,
            "view": true,
            "edit": true,
            "delete": true
        },
        {
            "id": 2,
            "menuName": "Candidate Evaluation",
            "list": true,
            "add": true,
            "view": true,
            "edit": true,
            "delete": true
        },
        {
            "id": 3,
            "menuName": "Candidate Database",
            "list": true,
            "add": true,
            "view": true,
            "edit": true,
            "delete": true
        },
        {
            "id": 4,
            "menuName": "Organisation",
            "list": true,
            "add": true,
            "view": true,
            "edit": true,
            "delete": true
        },
        {
            "id": 5,
            "menuName": "Employee",
            "list": true,
            "add": true,
            "view": true,
            "edit": true,
            "delete": true
        },
        {
            "id": 6,
            "menuName": "Department",
            "list": true,
            "add": true,
            "view": true,
            "edit": true,
            "delete": true
        },
        {
            "id": 7,
            "menuName": "Team",
            "list": true,
            "add": true,
            "view": true,
            "edit": true,
            "delete": true
        },
        {
            "id": 8,
            "menuName": "Designation",
            "list": true,
            "add": true,
            "view": true,
            "edit": true,
            "delete": true
        },
        {
            "id": 9,
            "menuName": "User Role",
            "list": true,
            "add": true,
            "view": true,
            "edit": true,
            "delete": true
        },
        {
            "id": 11,
            "menuName": "Web Browser",
            "list": true,
            "add": true,
            "view": true,
            "edit": true,
            "delete": true
        }
    ]

    const [openModal, setOpenModal] = useState(false);
    const [editRoleId, setEditRoleId] = useState(null);
    const [RoleDataLoading, setRoleDataLoading] = useState(false);
    const [RoleDataError, setRoleDataError] = useState(false);
    const [editOption, setEditOption] = useState(false);
    const [searchInputData, setSearchInputData] = useState(null);
    const [debouncedSearchData, setDebouncedSearchData] = useState(searchInputData);
    const [addRoleName, setAddRoleName] = useState(null);
    const [addMode, setAddMode] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [wholeData, setWholeData] = useState([])
    const [snackBarData, setSnackBarData] = useState({

        view: false,
        status: null,
        message: null

    })
    const [roleData, setroleData] = useState(defaultData);
    const [reload, setReload] = useState(false);


    const searchData = { "searchData ": debouncedSearchData }

    const userRoleNameUrl = `${URL}userrole/roleName`;

    const { data: userRolesData, loading: userRolesDataLoading, error: userRolesDataError } = useFetchData(userRoleNameUrl, searchData, debouncedSearchData, reload);

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 'max-content',
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 3,
        zIndex: 0,
    };

    const debouncedSetSearchData = debounce((value) => {
        setDebouncedSearchData(value);
    }, 500);


    const HandleOpenModalAndUPdateData = async (id) => {

        try {

            setRoleDataLoading(true);
            const response = await axios.get(`${URL}userrole/${id}`);
            if (response?.data?.status === 1) {
                setWholeData(response?.data?.data)
                setroleData(response?.data?.data?.data)
            }

        } catch (error) {

            if (error.response) {
                console.error('API error:', error.response.status, error.response.data);
            } else if (error.request) {
                console.error('No response received:', error.request);
            } else {
                console.error('Request setup error:', error.message);
            }
            setRoleDataError(true);

        } finally {
            setTimeout(() => {
                setRoleDataLoading(false)
            }, 300)
        }


    }


    const handlePermissionChange = (menuId, field, value) => {

        setroleData((prevState) => {
            if (!Array.isArray(prevState)) {
                console.error("State is not an array:", prevState);
                return prevState;
            }

            return prevState.map((menu) =>
                menu.id === menuId ? { ...menu, [field]: value } : menu
            );
        });
    };


    const uploadData = async () => {

        let updateDataUrl = `${URL}userrole`;

        try {

            const response = await axios.post(updateDataUrl, {
                data: roleData,
                editMode: editMode,
                addMode: addMode,
                editRoleId: editRoleId,
                roleName: addRoleName,
                employeenumber: employee_id,
            })

            if (response?.data?.status === 1) {

                setSnackBarData((prev) => ({
                    ...prev,
                    status: 1,
                    view: true,
                    message: response?.data?.responseMsg
                }))

                setOpenModal(!openModal)
                setroleData(defaultData)
                if (addMode) {
                    setAddRoleName(null);
                }
                if (editMode) {
                    setEditOption(!editOption);
                }
                setReload(!reload);

            } else {

                setSnackBarData((prev) => ({
                    ...prev,
                    status: 0,
                    view: true,
                    message: response?.data?.responseMsg
                }))

            }


        } catch (error) {

            setSnackBarData((prev) => ({
                ...prev,
                status: 0,
                view: true,
                message: " Something Went Wrong "
            }))

        }



    }

    return (

        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ paddingLeft: 6, paddingTop: 2 }}>

            <Modal
                open={openModal}
                onClose={() => {
                    setOpenModal(!openModal);
                    setroleData(defaultData);
                    setEditRoleId(null);
                    setEditOption(false);
                }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={{ zIndex: '99999' }}
            >

                <Box sx={style} >

                    {editMode && (

                        <>

                            {RoleDataLoading && <CircularProgress />}
                            {!RoleDataLoading && !RoleDataError && wholeData?.data?.length > 0 && (

                                <>

                                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>

                                        <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ paddingBottom: 2 }}>
                                            {wholeData?.userRoleName}
                                        </Typography>

                                        {!editOption &&
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={() => {
                                                    setEditOption(true);
                                                }}
                                                sx={{
                                                    zIndex: 5,
                                                    backgroundColor: '#1976d2',
                                                    borderRadius: '50%',
                                                    width: 40,
                                                    height: 40,
                                                    minWidth: 0,
                                                    padding: 0,
                                                    marginBottom: 2,
                                                    '&:hover': {
                                                        backgroundColor: '#1565c0',
                                                    },
                                                }}
                                            >
                                                <EditIcon />
                                            </Button>
                                        }
                                    </Box>

                                    <TableContainer component={Paper} variant='outlined'>

                                        <Table size='small' >

                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Menu Name</TableCell>
                                                    <TableCell>View</TableCell>
                                                    <TableCell>List</TableCell>
                                                    <TableCell>Add</TableCell>
                                                    <TableCell>Edit</TableCell>
                                                    <TableCell>Delete</TableCell>
                                                </TableRow>
                                            </TableHead>

                                            <TableBody >
                                                {roleData?.map((item) => (
                                                    <TableRow key={item?.id || item?.menuName}
                                                        style={{ opacity: !editOption ? 0.5 : 1, pointerEvents: !editOption ? 'none' : 'auto' }}
                                                    >
                                                        <TableCell sx={{ fontSize: 16 }} >

                                                            <TextField
                                                                fullWidth
                                                                variant="outlined"
                                                                value={item.menuName || ''}
                                                                onChange={(e) => handlePermissionChange(item.id, "menuName", e.target.value)}
                                                                sx={{
                                                                    '& .MuiInputBase-root': {
                                                                        height: 45,
                                                                        fontSize: '17px',
                                                                    },
                                                                    '& .MuiInputLabel-root': {
                                                                        top: '-4px',
                                                                        fontSize: '17px',
                                                                    },
                                                                    '& .MuiInputLabel-shrink': {
                                                                        top: 0,
                                                                    },
                                                                }}

                                                            />

                                                        </TableCell>
                                                        <TableCell>
                                                            <Checkbox
                                                                checked={item.view}
                                                                onChange={(e) =>
                                                                    handlePermissionChange(item.id, "view", e.target.checked)
                                                                }
                                                            />
                                                        </TableCell>
                                                        <TableCell>
                                                            <Checkbox
                                                                checked={item.list}
                                                                onChange={(e) =>
                                                                    handlePermissionChange(item.id, "list", e.target.checked)
                                                                }
                                                            />
                                                        </TableCell>
                                                        <TableCell>
                                                            <Checkbox
                                                                checked={item.add}
                                                                onChange={(e) =>
                                                                    handlePermissionChange(item.id, "add", e.target.checked)
                                                                }
                                                            />
                                                        </TableCell>
                                                        <TableCell>
                                                            <Checkbox
                                                                checked={item.edit}
                                                                onChange={(e) =>
                                                                    handlePermissionChange(item.id, "edit", e.target.checked)
                                                                }
                                                            />
                                                        </TableCell>
                                                        <TableCell>
                                                            <Checkbox
                                                                checked={item.delete}
                                                                onChange={(e) =>
                                                                    handlePermissionChange(item.id, "delete", e.target.checked)
                                                                }
                                                            />
                                                        </TableCell>
                                                    </TableRow>
                                                ))}

                                            </TableBody>

                                        </Table>

                                    </TableContainer>

                                    {editOption &&

                                        <Box sx={{ display: 'flex', justifyContent: 'flex-end', paddingTop: 2, gap: 3 }}>

                                            <Button variant='outlined' color='error' onClick={() => {
                                                setEditOption(false);
                                            }}>
                                                Cancel
                                            </Button>

                                            <Button variant='outlined' color='primary' onClick={uploadData}>
                                                Update
                                            </Button>

                                        </Box>
                                    }

                                </>

                            )}

                            {RoleDataError && !RoleDataLoading &&

                                <>
                                    <Typography>
                                        Something went wrong !!!!
                                    </Typography>
                                </>

                            }

                        </>

                    )}
                    {addMode && (
                        <>
                            <Typography display='flex' justifyContent='center' alignItems='center' sx={{ fontSize: 22, fontWeight: 800, pb: 2, color: 'primary' }}>
                                Create New Role
                            </Typography>
                            <Box display='flex' alignItems='center' justifyContent='center' >
                                <Typography fullWidth sx={{ fontSize: 18, fontWeight: 500, width: '30%' }}>
                                    Role Name :
                                </Typography>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    value={addRoleName || ''}
                                    onChange={(e) => setAddRoleName(e.target.value)}
                                    sx={{
                                        mb: 2,
                                        '& .MuiInputBase-root': {
                                            height: 45,
                                            fontSize: '17px',
                                        },
                                        '& .MuiInputLabel-root': {
                                            top: '-4px',
                                            fontSize: '17px',
                                        },
                                        '& .MuiInputLabel-shrink': {
                                            top: 0,
                                        },
                                    }}

                                />

                            </Box>

                            <TableContainer component={Paper} variant='outlined'>

                                <Table size='small' >

                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Menu Name</TableCell>
                                            <TableCell>View</TableCell>
                                            <TableCell>List</TableCell>
                                            <TableCell>Add</TableCell>
                                            <TableCell>Edit</TableCell>
                                            <TableCell>Delete</TableCell>
                                        </TableRow>
                                    </TableHead>

                                    <TableBody >
                                        {roleData?.map((item) => (
                                            <TableRow key={item?.id || item?.menuName}>

                                                <TableCell sx={{ fontSize: 16 }}>

                                                    <TextField
                                                        fullWidth
                                                        variant="outlined"
                                                        value={item.menuName || ''}
                                                        onChange={(e) => handlePermissionChange(item.id, "menuName", e.target.value)}
                                                        sx={{
                                                            '& .MuiInputBase-root': {
                                                                height: 45,
                                                                fontSize: '17px',
                                                            },
                                                            '& .MuiInputLabel-root': {
                                                                top: '-4px',
                                                                fontSize: '17px',
                                                            },
                                                            '& .MuiInputLabel-shrink': {
                                                                top: 0,
                                                            },
                                                        }}

                                                    />

                                                </TableCell>

                                                <TableCell>
                                                    <Checkbox
                                                        checked={item.view}
                                                        onChange={(e) =>
                                                            handlePermissionChange(item.id, "view", e.target.checked)
                                                        }
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    <Checkbox
                                                        checked={item.list}
                                                        onChange={(e) =>
                                                            handlePermissionChange(item.id, "list", e.target.checked)
                                                        }
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    <Checkbox
                                                        checked={item.add}
                                                        onChange={(e) =>
                                                            handlePermissionChange(item.id, "add", e.target.checked)
                                                        }
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    <Checkbox
                                                        checked={item.edit}
                                                        onChange={(e) =>
                                                            handlePermissionChange(item.id, "edit", e.target.checked)
                                                        }
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    <Checkbox
                                                        checked={item.delete}
                                                        onChange={(e) =>
                                                            handlePermissionChange(item.id, "delete", e.target.checked)
                                                        }
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        ))}

                                    </TableBody>


                                </Table>

                            </TableContainer>

                            <Box sx={{ display: 'flex', justifyContent: 'flex-end', paddingTop: 2, gap: 3 }}>

                                <Button variant='outlined' color='error' onClick={() => {
                                    setEditOption(false);
                                    setOpenModal(!openModal);
                                    setroleData(defaultData);
                                    setAddRoleName(null)
                                }}>
                                    Cancel
                                </Button>

                                <Button variant='outlined' color='primary' disabled={!addRoleName} onClick={uploadData}>
                                    Create
                                </Button>

                            </Box>
                        </>
                    )}

                </Box>

            </Modal>

            <Grid container xs={12} sx={{ backgroundColor: '', paddingBottom: 3 }}>

                <Grid item xs={6}>
                    <Typography
                        variant='h5'
                        component='h1'
                    > User Role Details </Typography>
                </Grid>

                <Grid item xs={4} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                    <Paper
                        variant='outlined'
                        sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 400, height: 37, outline: '1px solid' }}
                    >
                        <IconButton sx={{ p: '10px' }} aria-label="item">
                            <SearchIcon sx={{ color: 'black' }} />
                        </IconButton>
                        <InputBase
                            sx={{ ml: 1, flex: 1, color: 'black' }}
                            onChange={(e) => {
                                debouncedSetSearchData(e.target.value);
                            }}
                            placeholder=" Search User Role "
                            inputProps={{ 'aria-label': ' Search User Role ' }}
                        />
                    </Paper>
                </Grid>

                <Grid item xs={2} sx={{ backgroundColor: '', textAlign: 'right' }}>
                    <Button
                        variant='outlined'
                        onClick={() => {
                            setOpenModal(true);
                            setAddMode(true);
                            setEditMode(false);
                            setEditRoleId(null);
                        }}
                    >
                        Add User Role
                    </Button>
                </Grid>
            </Grid>

            {userRolesDataLoading && (

                <Box
                    sx={{
                        height: '70vh',
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <Grid
                        container
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Grid item sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <CircularProgress />
                        </Grid>

                    </Grid>

                </Box>

            )}

            {!userRolesDataLoading && (

                userRolesData?.data?.length > 0 ? (

                    <TableContainer component={Paper} variant="outlined" >
                        <Table size='small'>
                            <TableHead >
                                <TableRow>
                                    <TableCell sx={{ fontSize: 16 }}>User Role Name </TableCell>
                                    <TableCell sx={{ fontSize: 16, textAlign: 'center' }}>Created By </TableCell>
                                    <TableCell sx={{ fontSize: 16, textAlign: 'center' }}>Created At </TableCell>
                                    <TableCell sx={{ fontSize: 16, textAlign: 'center' }}>Updated By </TableCell>
                                    <TableCell sx={{ fontSize: 16, textAlign: 'center' }}>Updated At </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {userRolesData?.data?.length === 0 ? "" :
                                    userRolesData?.data?.map(item => {
                                        return (
                                            <TableRow key={item.id}>
                                                <TableCell sx={{ fontSize: 16, cursor: 'pointer' }} onClick={() => {
                                                    setEditRoleId(item.id);
                                                    setOpenModal(!openModal)
                                                    setEditMode(true);
                                                    setAddMode(false)
                                                    HandleOpenModalAndUPdateData(item.id);
                                                }}
                                                >{item?.name}</TableCell>

                                                <TableCell align='center' sx={{ fontSize: 16 }}>
                                                    {item?.created_by ? item?.created_by : '--'}
                                                </TableCell>

                                                <TableCell align='center' sx={{ fontSize: 16 }}>
                                                    {item?.created_at ? item?.created_at : '--'}
                                                </TableCell>

                                                <TableCell align='center' sx={{ fontSize: 16 }}>
                                                    {item?.updated_by ? item?.updated_by : '--'}
                                                </TableCell>

                                                <TableCell align='center' sx={{ fontSize: 16 }}>
                                                    {item?.updated_at ? item?.updated_at : '--'}
                                                </TableCell>

                                            </TableRow>
                                        )
                                    })
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                )
                    :
                    <DatanotFound />
            )}


            <Snackbar
                open={snackBarData.view}
                autoHideDuration={3000}
                onClose={() =>
                    setSnackBarData((prev) => ({
                        ...prev,
                        view: false,
                    }))
                }
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                sx={{
                    zIndex: 99999,
                }}
            >
                <Alert
                    onClose={() =>
                        setSnackBarData((prev) => ({
                            ...prev,
                            view: false,
                        }))
                    }
                    severity={snackBarData.status === 1 ? 'success' : 'error'}
                    variant="filled"
                    sx={{ width: '100%', zIndex: 99999 }}
                >
                    {snackBarData?.message}
                </Alert>
            </Snackbar>


        </Grid>
    )
}

export default UserRoleManagement