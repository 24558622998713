import React, { createContext, useState, useContext } from 'react';

const SharedContext = createContext();


export function SharedProvider({ children }) {
    const [sharedTab, setSharedTab] = useState({
        Tabname: null,
        TabUrl: null,
        active: 0,
        backendUrl: null
    });

    const [rerender, setRerender] = useState(false)

    const [insertRequest, setInsertRequest] = useState('')

    const [employeeAddTab, setEmployeeAddTab] = useState({
        candidateId: null,
        status: 0
    });

    const [userRoleManagement, setuserRoleManagement] = useState(
        JSON.parse(sessionStorage.getItem("userRole")) || []
    );

    const [chatLists, setChatLists] = useState([]);

    const [refreshLeftPanel, setRefreshLeftPanel] = useState(false);

    const [notificationContent, setNotificationContent] = useState({
        title: "",
        options: {},
    });
    const [notificationPermissionGranted, setNotificationPermissionGranted] = useState(false);

    const [deleteChatId, setDeleteChatId] = useState(null);

    const [openMessagPanel, setOpenMessagePanel] = useState(false);

    const userinfo = JSON.parse(sessionStorage.getItem("user_info"));

    const [CurrentChatID, setCurrentChatID] = useState(null);

    return (
        <SharedContext.Provider
            value={{
                sharedTab, setSharedTab,
                rerender, setRerender,
                insertRequest, setInsertRequest,
                employeeAddTab, setEmployeeAddTab,
                userRoleManagement, setuserRoleManagement,
                chatLists, setChatLists,
                refreshLeftPanel, setRefreshLeftPanel,
                notificationContent, setNotificationContent,
                notificationPermissionGranted, setNotificationPermissionGranted,
                deleteChatId, setDeleteChatId,
                openMessagPanel, setOpenMessagePanel,
                userinfo,
                CurrentChatID, setCurrentChatID,
            }}>
            {children}
        </SharedContext.Provider>
    );
}


export function useSharedContext() {
    return useContext(SharedContext);
}
