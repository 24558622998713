import { createSlice } from "@reduxjs/toolkit";

export const CounterSlice = createSlice({
    name: 'counter',
    initialState: {
        value: 0
    },
    reducers: {
        Increment: state => {
            state.value += 1
        },
        Decrement: state => {
            state.value -= 1
        },
        IncrementPayloadByAmount: (state, action) => {
            state.value += action.payload
        }
    }
})


export const { Increment, Decrement, IncrementPayloadByAmount } = CounterSlice.actions;

export default CounterSlice.reducer;