import { io } from 'socket.io-client';
import URL from './Components/Global/Utils/url_route';

const socket = io(URL, {
    reconnectionAttempts: 5,
    timeout: 10000,
    transports: ['websocket']
});

socket.on('connect', () => {
    console.log('Connected to Socket.IO server!');
});

socket.on('disconnect', () => {
    console.log('Disconnected from Socket.IO server!');
});

export default socket;