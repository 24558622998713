import React, { act, useEffect, useState } from "react";
import { styled } from "@mui/system";
import IconButton from "@mui/material/IconButton";
import { useLocation, useNavigate } from 'react-router-dom';
import AddTaskIcon from '@mui/icons-material/AddTask';
import StorageRoundedIcon from '@mui/icons-material/StorageRounded';
import PersonSearchSharpIcon from '@mui/icons-material/PersonSearchSharp';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import GroupsIcon from '@mui/icons-material/Groups';
import BusinessIcon from '@mui/icons-material/Business';
import BadgeIcon from '@mui/icons-material/Badge';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import CandidateInterview from "../CandidateInterview";
import MOSDashboard from "../Dashboard";
import MOSCandidate from "../Candidate";
import EmployeeTable from "../EmployeeTable";
import EmployeeForm from "../EmployeeOnboard";
import DeparmentMaster from "../Departments";
import TeamMaster from "../Team";
import DesignationMaster from "../Designation";
import UserRoleManagement from "../userRoleManagement";
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import TravelExploreIcon from '@mui/icons-material/TravelExplore';
import { useSharedContext } from "../../Context";
import { Collapse } from "@mui/material";
import socket from "../../useSocket";
import BrowserComponent from "../Browser";


const SidebarContainer = styled('div', {
    shouldForwardProp: (prop) => prop !== 'isopen'
})(({ isopen }) => ({
    height: "100%",
    width: isopen ? "280px" : "60px",
    position: "fixed",
    zIndex: 9999,
    left: 0,
    backgroundColor: "#142850",
    overflowX: "hidden",
    overflowY: "auto",
    transition: "width 0.5s",
    whiteSpace: "nowrap",
}));

const MainContainer = styled('div')(() => ({
    padding: "16px",
    marginLeft: "40px",
    zIndex: 9999,
}));

const SidebarIcon = styled(IconButton, {
    shouldForwardProp: (prop) => prop !== 'isactive'
})(({ isopen, isactive }) => ({
    color: isactive ? 'white' : "gray",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: 'center',
    padding: "25px 15px",
    fontSize: "20px",
    transition: "color 0.5s",
    "&:hover": {
        color: isopen ? "white" : "#f1f1f1",
    },
}));

function Sidebar() {

    const userinfo = JSON.parse(sessionStorage.getItem("user_info"));

    const { sharedTab, setSharedTab, employeeAddTab, setEmployeeAddTab, userRoleManagement, chatLists, setChatLists } = useSharedContext();
    const { search } = useLocation();
    const queryParams = new URLSearchParams(search);
    const [isopen, setIsOpen] = useState(false);
    const [activeTab, setActiveTab] = useState(() => sessionStorage.getItem('activeTab') || 'info');
    const [orgMenuOpen, setOrgMenuOpen] = useState(false);


    const navigate = useNavigate();

    useEffect(() => {
        if (activeTab !== 'EmployeeMaster2') {
            setEmployeeAddTab((prev) => ({ ...prev, candidateId: null, status: 0 }));
        }
    }, [activeTab, setEmployeeAddTab]);


    useEffect(() => {

        socket.on('refreshChatPage', () => {

            socket.emit('fetchChats', { data: userinfo?.user_name, name: '' });

            socket.on('FetchedChatsData', (data) => {
                setChatLists(data?.data);
            });
        });

        return () => { };
    }, [socket, userinfo]);


    React.useEffect(() => {
        if (sharedTab.active === 1) {
            setActiveTab('particularEmployee')
        } else if (employeeAddTab.status === 1) {
            setActiveTab('EmployeeMaster2')
        } else if (employeeAddTab.status === 2) {
            setActiveTab('EmployeeMaster')
        } else if (employeeAddTab.status === 3) {
            setActiveTab('info')
        }

    }, [sharedTab.active, employeeAddTab])


    useEffect(() => {
        sessionStorage.setItem("activeTab", activeTab);
        const routes = {
            info: "/dashboard",
            CandidateEvaluation: "/dashboard/evalution",
            CandidateDatabase: "/dashboard/candidate-master",
            particularEmployee: sharedTab.TabUrl,
            EmployeeMaster: "/dashboard/employee-master",
            EmployeeMaster2: "/dashboard/employee-master2",
            Team: "/dashboard/team",
            Department: "/dashboard/department",
            Designation: "/dashboard/designation",
            UserRole: "/dashboard/userrole",
            WebBrowser: "/dashboard/browser",
        };
        navigate(routes[activeTab] || "/");
    }, [activeTab, navigate, sharedTab]);


    const renderContent = () => {
        switch (activeTab) {
            case 'info': return <MOSDashboard />;
            case 'CandidateEvaluation': return <CandidateInterview />;
            case 'CandidateDatabase': return <MOSCandidate />;
            case 'particularEmployee': {
                const employid = queryParams.get('employid');
                const fromdate = queryParams.get('fromdate');
                const todate = queryParams.get('todate');
                return <MOSCandidate emp_id={employid} fromDate={fromdate} toDate={todate} />;
            }
            case 'EmployeeMaster': return <EmployeeTable />;
            case 'EmployeeMaster2': return <EmployeeForm />;
            case 'Department': return <DeparmentMaster />;
            case 'Team': return <TeamMaster />;
            case 'Designation': return <DesignationMaster />;
            case 'UserRole': return <UserRoleManagement />;
            case 'WebBrowser': return <BrowserComponent />;
            default: return <MOSDashboard />;
        }
    };


    const handleMouseEnter = () => setIsOpen(true);
    const handleMouseLeave = () => setIsOpen(false);

    const handleTabChange = (tab) => {
        setActiveTab(tab);
        setSharedTab({ Tabname: null, TabUrl: null, active: 0, backendUrl: null });
    };


    return (
        <>
            <SidebarContainer
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                isopen={isopen}
            >
                <SidebarIcon
                    disableRipple
                    aria-label="info"
                    isopen={isopen}
                    onClick={() => handleTabChange('info')}
                    isactive={activeTab === 'info'}
                    disabled={!userRoleManagement?.[0]?.view}
                >
                    <AddTaskIcon sx={{ fontSize: 30 }} />
                    {isopen && <span>&nbsp;&nbsp;&nbsp;{userRoleManagement?.[0]?.name}</span>}
                </SidebarIcon>

                <SidebarIcon
                    disableRipple
                    aria-label="CandidateEvaluation"
                    isopen={isopen}
                    onClick={() => handleTabChange('CandidateEvaluation')}
                    isactive={activeTab === 'CandidateEvaluation'}
                    style={{ marginTop: '-20px' }}
                    disabled={!userRoleManagement?.[1]?.view}
                >
                    <PersonSearchSharpIcon sx={{ fontSize: 35 }} />
                    {isopen && <span>&nbsp;&nbsp;&nbsp;{userRoleManagement?.[1]?.name}</span>}
                </SidebarIcon>

                <SidebarIcon
                    disableRipple
                    aria-label="CandidateDatabase"
                    isopen={isopen}
                    onClick={() => handleTabChange('CandidateDatabase')}
                    isactive={activeTab === 'CandidateDatabase'}
                    style={{ marginTop: '-20px' }}
                    disabled={!userRoleManagement?.[2]?.view}
                >
                    <StorageRoundedIcon sx={{ fontSize: 30 }} />
                    {isopen && <span>&nbsp;&nbsp;&nbsp;{userRoleManagement?.[2]?.name}</span>}
                </SidebarIcon>

                <SidebarIcon
                    disableRipple
                    isopen={isopen}
                    style={{ marginTop: '-20px' }}
                    disabled={!userRoleManagement?.[3]?.view}
                    onClick={() => setOrgMenuOpen(!orgMenuOpen)}
                >
                    <CorporateFareIcon sx={{ fontSize: 30 }} />
                    {isopen && <span>&nbsp;&nbsp;&nbsp;{userRoleManagement?.[3]?.name}</span>}
                    {isopen ? (orgMenuOpen ? <ExpandLess /> : <ExpandMore />) : null}
                </SidebarIcon>


                <Collapse in={orgMenuOpen} >

                    <div style={{ marginLeft: '45px', marginTop: '0px' }}>

                        <SidebarIcon
                            disableRipple
                            aria-label="EmployeeMaster"
                            isopen={isopen}
                            onClick={() => handleTabChange('EmployeeMaster')}
                            isactive={activeTab === 'EmployeeMaster'}
                            style={{ cursor: 'pointer', display: 'flex', alignItems: 'center', marginTop: '-30px' }}
                            disabled={!userRoleManagement?.[4]?.view}
                        >
                            <SupervisorAccountIcon sx={{ fontSize: 20 }} />
                            {isopen && <span>&nbsp;&nbsp;&nbsp;{userRoleManagement?.[4]?.name}</span>}
                        </SidebarIcon>

                        <SidebarIcon
                            disableRipple
                            aria-label="Department"
                            isopen={isopen}
                            onClick={() => handleTabChange('Department')}
                            isactive={activeTab === 'Department'}
                            style={{ cursor: 'pointer', display: 'flex', alignItems: 'center', marginTop: '-30px' }}
                            disabled={!userRoleManagement?.[5]?.view}
                        >
                            <BusinessIcon sx={{ fontSize: 20 }} />
                            {isopen && <span>&nbsp;&nbsp;&nbsp;{userRoleManagement?.[5]?.name}</span>}
                        </SidebarIcon>

                        <SidebarIcon
                            disableRipple
                            aria-label="Team"
                            isopen={isopen}
                            onClick={() => handleTabChange('Team')}
                            isactive={activeTab === 'Team'}
                            style={{ cursor: 'pointer', display: 'flex', alignItems: 'center', marginTop: '-30px' }}
                            disabled={!userRoleManagement?.[6]?.view}
                        >
                            <GroupsIcon sx={{ fontSize: 20 }} />
                            {isopen && <span>&nbsp;&nbsp;&nbsp;{userRoleManagement?.[6]?.name}</span>}
                        </SidebarIcon>

                        <SidebarIcon
                            disableRipple
                            aria-label="Designation"
                            isopen={isopen}
                            onClick={() => handleTabChange('Designation')}
                            isactive={activeTab === 'Designation'}
                            style={{ cursor: 'pointer', display: 'flex', alignItems: 'center', marginTop: '-30px' }}
                            disabled={!userRoleManagement?.[7]?.view}
                        >
                            <BadgeIcon sx={{ fontSize: 20 }} />
                            {isopen && <span>&nbsp;&nbsp;&nbsp;{userRoleManagement?.[7]?.name}</span>}
                        </SidebarIcon>

                        <SidebarIcon
                            disableRipple
                            aria-label="UserRole"
                            isopen={isopen}
                            onClick={() => handleTabChange('UserRole')}
                            isactive={activeTab === 'UserRole'}
                            style={{ cursor: 'pointer', display: 'flex', alignItems: 'center', marginTop: '-30px' }}
                            disabled={!userRoleManagement?.[8]?.view}
                        >
                            <ManageAccountsIcon sx={{ fontSize: 20 }} />
                            {isopen && <span>&nbsp;&nbsp;&nbsp;{userRoleManagement?.[8]?.name}</span>}
                        </SidebarIcon>

                    </div>

                </Collapse>

                <SidebarIcon
                    disableRipple
                    aria-label="WebBrowser"
                    isopen={isopen}
                    style={{ marginTop: '-20px' }}
                    onClick={() => handleTabChange('WebBrowser')}
                    disabled={!userRoleManagement?.[9]?.view}
                    isactive={activeTab === 'WebBrowser'}
                >
                    <TravelExploreIcon sx={{ fontSize: 30 }} />
                    {isopen && <span>&nbsp;&nbsp;&nbsp;{userRoleManagement?.[9]?.name}</span>}
                </SidebarIcon>



            </SidebarContainer >
            <MainContainer>
                {renderContent()}
            </MainContainer>
        </>
    );
}

export default Sidebar;


















