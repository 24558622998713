import { Box, Divider, Drawer, Typography } from '@mui/material'
import React from 'react'
import ChatPanel from '../Chat'
import { useSharedContext } from '../../Context';
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';
import { ThemeProvider, createTheme } from '@mui/material';

const theme = createTheme();

const ChatDrawer = () => {

    const { openMessagPanel, setOpenMessagePanel } = useSharedContext();


    return (

        <ThemeProvider theme={theme}>

            <Drawer
                anchor={'right'}
                open={openMessagPanel}
                onClose={() => setOpenMessagePanel(false)}
                hideBackdrop={true}
                sx={{
                    '& .MuiDrawer-paper': {
                        width: '45%',
                        [theme.breakpoints.only('sm')]: {
                            width: '90%',
                        },
                        [theme.breakpoints.only('md')]: {
                            width: '50%'
                        },
                        [theme.breakpoints.only('lg')]: {
                            width: '50%'
                        }
                    },
                }}

            >
                <Box
                    sx={{
                        width: '100%',
                        height: '100%',
                        pt: 8,
                    }}
                    role="presentation"
                >

                    <Box
                        sx={{
                            height: '5%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}
                    >
                        <Box sx={{ flex: 1 }} />

                        <Box
                            sx={{
                                flex: 1,
                                display: 'flex',
                                justifyContent: 'center',
                            }}
                        >
                            <Typography variant='h5' fontWeight={600}>
                                Chats
                            </Typography>
                        </Box>

                        <Box
                            sx={{
                                flex: 1,
                                display: 'flex',
                                justifyContent: 'flex-end',
                                pr: 2,

                            }}
                        >
                            <HighlightOffRoundedIcon
                                sx={{
                                    cursor: 'pointer',
                                    fontSize: '35px',
                                    '&:hover': {
                                        color: 'red'
                                    }
                                }}
                                onClick={() => setOpenMessagePanel(false)}
                            />
                        </Box>

                    </Box>
                    <Divider />
                    <Box>

                        <ChatPanel />

                    </Box>

                </Box>
                <Box
                    sx={{
                        position: 'fixed',
                        top: 0,
                        right: 0,
                        bottom: 0,
                        left: 0,
                        backgroundColor: 'transparent',
                        zIndex: -1,
                    }}
                    onClick={() => setOpenMessagePanel(false)}
                />
            </Drawer>

        </ThemeProvider>


    )
}

export default ChatDrawer