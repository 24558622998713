import { Button, Grid } from '@mui/material'
import React, { useState } from 'react'

const BrowserComponent = () => {
    const [url, setUrl] = useState("");
    const [iframeUrl, setIframeUrl] = useState("");
    const [error, setError] = useState("");

    const handleInputChange = (e) => {
        setUrl(e.target.value);
        setError("");
    };

    const handleLoadUrl = () => {
        if (url.trim()) {
            if (url.startsWith("https://")) {
                setIframeUrl(url.trim());
                setError("");
            } else {
                setError("URL must start with 'https://'");
            }
        } else {
            setError("Please enter a valid URL");
        }
    };

    return (
        <Grid container sx={{ width: "100%", height: "100vh", paddingLeft: 4, paddingTop: 3, paddingRight: 3 }}>
            <Grid
                sx={{
                    width: "fit-content",
                    margin: "auto",
                }}
            >
                <input
                    type="text"
                    value={url}
                    onChange={handleInputChange}
                    placeholder="Enter URL (e.g., https://example.com)"
                    style={{
                        padding: "10px",
                        width: "300px",
                        border: "1px solid #ccc",
                        borderRadius: "4px",
                        marginRight: "10px",
                    }}
                />
                <Button
                    onClick={handleLoadUrl}
                    style={{
                        padding: "10px 20px",
                        backgroundColor: "#007BFF",
                        color: "#fff",
                        border: "none",
                        borderRadius: "4px",
                        cursor: "pointer",
                    }}
                >
                    Load URL
                </Button>
                {error && (
                    <div
                        style={{
                            color: "red",
                            marginTop: "10px",
                        }}
                    >
                        {error}
                    </div>
                )}
            </Grid>

            <Grid sx={{ width: "100%", height: "100%", pt: 2 }}>
                <iframe
                    src={iframeUrl}
                    title="URL Viewer"
                    width="100%"
                    height="100%"
                    frameBorder="0"
                />
            </Grid>
        </Grid>
    );
};


export default BrowserComponent 
