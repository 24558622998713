import React, { useState, useEffect } from "react";
import ReactNotification from "react-web-notification";
import { useSharedContext } from "../../Context";


const WebNotificationComponent = () => {

    const { notificationContent, setNotificationContent, notificationPermissionGranted, setNotificationPermissionGranted } = useSharedContext();

    useEffect(() => {
        if ("Notification" in window && Notification.permission !== "granted") {
            Notification.requestPermission().then((permission) => {
                setNotificationPermissionGranted(permission === "granted");
            });
        } else if ("Notification" in window) {
            setNotificationPermissionGranted(Notification.permission === "granted");
        }
    }, []);


    const handleNotificationClick = (event) => {
        //Using this We can able to navigating to the Chat Section Using this Link....
    };

    const handleNotificationError = (error) => {
        console.error("Notification error:", error);
    };

    const handleNotificationClose = () => {
        setNotificationContent({
            title: "",
            options: {},
        })
    };


    return (

        <div style={{ textAlign: "center", marginTop: "50px" }}>
            <ReactNotification
                title={notificationContent.title}
                options={notificationContent.options}
                onClick={handleNotificationClick}
                onError={handleNotificationError}
                onClose={handleNotificationClose}
                ignore={!notificationContent.title}
                timeout={20000}
            />
        </div>
    );
};

export default WebNotificationComponent;
