import { Routes, Route, BrowserRouter, Navigate } from "react-router-dom";
import MegaOpesCandidateRegistration from "./Components/CandidateRegistration";
import CandidateInterview from "./Components/CandidateInterview";
import Page404 from "./Components/Global/Page404";
import MOSCandidate from "./Components/Candidate";
import ThankYou from "./Components/Global/ThankYou";
import MOSLogin from "./Components/Login";
import MOSLayout from "./Components/Layout";
import MOSDashboard from "./Components/Dashboard";
import Employee from "./Components/EmployeeTable";
import EmployeeTable1 from "./Components/EmployeeTable";
import TeamMaster from "./Components/Team";
import DeparmentMaster from "./Components/Departments";
import DesignationMaster from "./Components/Designation";
import UserRoleManagement from "./Components/userRoleManagement";
import UnauthorizedPage from "./Components/Global/UnauthorizedPage";
import BrowserComponent from "./Components/Browser";
import React from 'react';
import { Provider } from "react-redux";
import store from "./store"
import { fetchUserIp } from "./ApiRestriction";


function App() {

  const userinfo = JSON.parse(sessionStorage.getItem("user_info"));
  const AuthorizedAccess = fetchUserIp();

  return (
    <div >

      <Provider store={store}>
        <BrowserRouter>
          {AuthorizedAccess ? (
            <Routes>

              <Route
                path="/candidate"
                element={<MegaOpesCandidateRegistration />}
              />

              {/* <Route path="/" element={<Landing />} /> */}
              <Route path="/" element={<Navigate to="/login" replace />} />
              <Route path="/thankyou" element={<ThankYou />} />
              <Route path="/login" element={<MOSLogin />} />
              <Route path="*" element={<Page404 />} />
              <Route path="/dashboard" element={<MOSLayout />}>
                <Route index element={<MOSDashboard />} />
                <Route
                  path="/dashboard/evalution"
                  element={<CandidateInterview />}
                />
                <Route
                  path="/dashboard/candidate-master"
                  element={<MOSCandidate />}
                />
                <Route
                  path="/dashboard/employee-master"
                  element={<Employee />}
                />
                <Route path="/dashboard/employee-master2" element={<EmployeeTable1 />} />

                <Route path="/dashboard/department" element={<DeparmentMaster />} />

                <Route path="/dashboard/team" element={<TeamMaster />} />

                <Route path="/dashboard/designation" element={<DesignationMaster />} />

                <Route path="/dashboard/userrole" element={<UserRoleManagement />} />

                <Route path="/dashboard/browser" element={<BrowserComponent />} />

              </Route>
            </Routes>
          )
            :
            (
              <Routes>
                <Route path="/login" element={<UnauthorizedPage />} />
              </Routes>
            )
          }
        </BrowserRouter>
      </Provider>

    </div>
  );
}


export default App;